<template>
<div class="col">
    <div :hidden="loaded == false">
        <div class="d-flex justify-content-between flex-wrap h-100">
            <div class="justify-content-between row d-flex flex-wrap pt-lg-2">
                <Heading :pageTitle="'Admin Panel'" :username="username" :profilePicLink="profilePicUrl" class="px-3 pb-3 mt-sm-1 mt-0 mb-3 mb-sm-1"/>
                <div class="col-lg-3 col-xl-2 pb-0 mb-sm-4 mb-0">
                    <div class="jumbotron rounded-corner product-box pb-0 h-100 px-3" id="total-proxy-box">
                        <div>
                            <h3 class="tiny-title">Total Proxies</h3>
                            <div class="mt-4 progress-bar-container" id="dc-count">
                            </div>
                        </div>
                    </div>
                </div>
                <!--
                <div class="col-lg-3 col-xl-2 pb-0 mb-sm-4 mb-0">
                    <div class="jumbotron rounded-corner product-box pb-0 h-100 px-3 d-flex flex-row justify-content-between flex-column">
                        <div>
                            <h3 class="tiny-title">Budget Balance</h3>
                            <div class="mt-4 progress-bar-container" id="budget-balance">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-xl-2 pb-0 mb-sm-4 mb-0">
                    <div class="jumbotron rounded-corner product-box pb-0 h-100 px-3 d-flex flex-row justify-content-between flex-column">
                        <div>
                            <h3 class="tiny-title">Premium Balance</h3>
                            <div class="mt-4 progress-bar-container" id="premium-balance">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-xl-2 pb-0 mb-sm-4 mb-0">
                    <div class="jumbotron rounded-corner product-box pb-0 h-100 px-3 d-flex flex-row justify-content-between flex-column">
                        <div>
                            <h3 class="tiny-title">Mesh Balance</h3>
                            <div class="mt-4 progress-bar-container" id="mesh-balance">
                            </div>
                        </div>
                    </div>
                </div>
                -->
                <div class="col-lg-3 col-xl-2 pb-0 mb-4">
                    <div class="jumbotron rounded-corner product-box pb-0 h-100 px-3" id="total-proxy-box">
                        <h3 class="tiny-title">Budget Balance</h3>
                        <div class="mt-4 progress-bar-container" id="budget-balance">
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-xl-2 pb-0 mb-sm-4 mb-4">
                    <div class="jumbotron rounded-corner product-box pb-0 h-100 px-3" id="total-proxy-box">
                        <h3 class="tiny-title">Premium Balance</h3>
                        <div class="mt-4 progress-bar-container" id="premium-balance">
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-xl-2 pb-0 mb-4">
                    <div class="jumbotron rounded-corner product-box pb-0 h-100 px-3" id="total-proxy-box">
                        <h3 class="tiny-title">Mesh Balance</h3>
                        <div class="mt-4 progress-bar-container" id="mesh-balance">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pb-0 pl-lg-2 mb-4">
                    <div class="jumbotron rounded-corner product-box h-100 pb-0 progress-box" id="order-box">
                        <h3 class="tiny-title">Orders</h3>
                        <div class="box-background rounded-corner w-100 mt-4">
                            <div class="row pb-2 mx-0" id="top-row">
                                <div class="col-6 px-0 cell semi-bold">
                                    Plan
                                </div>
                                <div class="col-2 px-0 cell semi-bold">
                                    Quantity
                                </div>
                                <div class="col-4 px-0 cell semi-bold">
                                    User
                                </div>
                            </div>
                            <div v-for="order in orders" :key="order.timestamp" class="row mt-3 mb-3 pb-1 mx-0">
                                <div class="col-6 px-0 cell">
                                    {{ order.name }}
                                </div>
                                <div class="col-2 px-0 cell">
                                    {{ order.quantity }}
                                </div>
                                <div class="col-4 px-0 cell">
                                    {{ order.displayName }}#{{ order.tag }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-xl-2 pb-0 mb-4">
                    <div class="jumbotron rounded-corner product-box pb-0 h-100 px-3" id="total-proxy-box">
                        <h3 class="tiny-title mb-3">Edit Datacenter Stock</h3>
                        <div>
                            <form v-on:submit.prevent class="w-100 h-100 mb-5 pb-5">
                                <label for="plan" class="small dark-text">Select Plan</label> <br>
                                <select v-model="selectedPlanStock" class="dropdown no-border dark-text px-3 w-100">
                                    <option value="zalando">Zalando DCs</option>
                                    <option value="isp">ISP Proxies</option>
                                    <option value="dc">DCs</option>
                                    <option value="daily_dc">Daily DCs</option>
                                </select>
                                <input type="number" v-model="changeStockInput" class="no-border w-100 mt-2" id="change-stock-input">  
                                    <button v-on:click="addStock" class="purchase-button no-border w-100 mt-3">
                                        Add Stock
                                    </button>
                                    <button v-on:click="removeStock" class="purchase-button no-border w-100 mt-2">
                                        Remove Stock
                                    </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-xl-6 pl-lg-2 mb-4" id="show-div">
                    <div class="jumbotron rounded-corner product-box h-100 pb-0 px-4 mb-0">
                        <h3 class="tiny-title mb-3">Show Proxies of User</h3>
                        <form v-on:submit.prevent class="w-100 h-100 mb-5 pb-5">
                            <div class="d-flex flex-column justify-content-between h-100">
                                <div class="row w-100 mx-0">
                                    <div class="col-12 col-xl-4 mb-2 pl-0 pr-xl-4 pr-0">
                                        <input type="text" v-model="searchUserId" class="no-border w-100" id="webhook" placeholder="Input Discord id...">  
                                    </div>
                                    <div class="col-xl-4 col-lg-6 mb-2 pl-0 pr-lg-3 pr-0 pt-lg-0 pt-1">
                                        <select v-model="selectedSearchPool" class="dropdown no-border dark-text px-3 w-100">
                                            <option value="zalando">Zalando DCs</option>
                                            <option value="isp">ISP Proxies</option>
                                            <option value="dc">DCs</option>
                                            <option value="daily_dc">Daily DCs</option>
                                        </select> 
                                    </div>
                                    <div class="col-xl-4 col-lg-6 mb-2 pl-0 pr-lg-3 pr-0 pt-lg-0 pt-1">
                                        <button v-on:click="getUserProxies" class="h-100 purchase-button no-border w-100">
                                            Search
                                        </button>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 mb-2 pl-0 pr-lg-3 pr-0 pt-lg-0 pt-1">
                                        <button v-on:click="copyProxies" class="purchase-button mb-2 no-border w-100">
                                            Copy Proxies
                                        </button>
                                    </div>
                                    <!--
                                    <div class="col-xl-4 col-lg-6 mb-2 pl-0 pr-lg-3 pr-0 pt-lg-0 pt-1">
                                        <button v-on:click="removeProxies" class="purchase-button mb-2 no-border w-100">
                                            Remove proxies
                                        </button>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 mb-3 px-0">
                                        <button v-on:click="reassignProxies" class="purchase-button no-border w-100">
                                            Reassign proxies
                                        </button>
                                    </div>-->
                                </div>

                                <div class="w-100 text-area-container">
                                    <div id="user-proxy-jumbo" class="jumbotron pt-3 text-area rounded-corner pb-0 h-100 d-flex align-items-start flex-column">
                                        <p v-for="proxy in userProxies" :key="proxy.proxy" class="mb-0">
                                            {{ proxy.proxy }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-12 col-lg-4 pl-lg-2 mt-sm-4 mt-lg-0">
                    <div class="jumbotron rounded-corner product-box h-100 mb-0 pb-4">
                        <h3 class="tiny-title">Monthly Overview</h3>
                        <h4 class="tiny-title subtitle-muted mt-3">Total sales this month:</h4>
                        <h2 class="fixed-medium-text medium mb-3">{{ totalSales }} €</h2>
                        <h4 class="tiny-title subtitle-muted pt-3">Sold plans this month:</h4>
                        <h3 class="tiny-title mb-3">Daily Datacenter Proxies - {{ dailyDcSales }}</h3>
                        <h3 class="tiny-title mb-3">Datacenter Proxies - {{ dcSales }}</h3>
                        <h3 class="tiny-title mb-3">ISP Proxies - {{ ispSales }}</h3>
                        <h3 class="tiny-title mb-3">Zalando DCs - {{ zalandoSales }}</h3>
                        <h3 class="tiny-title mb-3">Residential Mesh - {{ meshSales }} GB</h3>
                        <h3 class="tiny-title mb-3">Residential Budget - {{ budgetSales }} GB</h3>
                        <h3 class="tiny-title mb-3">Residential Premium - {{ premiumSales }} GB</h3>
                        <!--<h3 class="tiny-title mb-3">Residential Mesh - 42 GB</h3>-->
                    </div>
                </div>
                <div class="col-12 col-lg-4 pl-lg-2 mt-sm-4 mt-lg-0">
                    <div class="jumbotron rounded-corner product-box h-100 mt-sm-3 mt-4 mb-0 pb-0" id="total-proxy-box">
                        <h3 class="tiny-title mb-3">Disable Services</h3>
                        <form v-on:submit.prevent>
                            <div class="d-flex flex-sm-row flex-column flex-wrap">
                                <div class="mr-4">
                                    <label for="customSwitch1" class="tiny-title mr-2 dark-text">Residential</label>
                                    <div class="custom-control custom-switch d-inline-block">
                                    <input v-model="residentialPageStatus" type="checkbox" class="custom-control-input" id="customSwitch1">
                                        <label class="custom-control-label" for="customSwitch1"></label>
                                    </div>
                                </div>

                                <div class="mr-4">
                                    <label for="customSwitch2" class="tiny-title mr-2 dark-text">Datacenter</label>
                                    <div class="custom-control custom-switch d-inline-block">
                                    <input v-model="datacenterPageStatus" type="checkbox" class="custom-control-input" id="customSwitch2">
                                        <label class="custom-control-label" for="customSwitch2"></label>
                                    </div>
                                </div>

                                <div>
                                    <label for="customSwitch3" class="tiny-title mr-2 dark-text">Shop</label>
                                    <div class="custom-control custom-switch d-inline-block">
                                    <input v-model="shopPageStatus" type="checkbox" class="custom-control-input" id="customSwitch3">
                                        <label class="custom-control-label" for="customSwitch3"></label>
                                    </div>
                                </div>

                                <div>
                                    <label for="customSwitch3" class="tiny-title mr-2 dark-text">Residential Products</label>
                                    <div class="custom-control custom-switch d-inline-block">
                                    <input v-model="residentialProductStatus" type="checkbox" class="custom-control-input" id="customSwitch4">
                                        <label class="custom-control-label" for="customSwitch4"></label>
                                    </div>
                                </div>

                                <div>
                                    <label for="customSwitch3" class="tiny-title mr-2 dark-text">Datacenter Products</label>
                                    <div class="custom-control custom-switch d-inline-block">
                                    <input v-model="datacenterProductStatus" type="checkbox" class="custom-control-input" id="customSwitch5">
                                        <label class="custom-control-label" for="customSwitch5"></label>
                                    </div>
                                </div>

                                <div>
                                    <label for="customSwitch6" class="tiny-title mr-2 dark-text">Residential Mesh</label>
                                    <div class="custom-control custom-switch d-inline-block">
                                    <input v-model="residentialMeshPageStatus" type="checkbox" class="custom-control-input" id="customSwitch6">
                                        <label class="custom-control-label" for="customSwitch6"></label>
                                    </div>
                                </div>
                            </div>

                            <button v-on:click="saveStatus" class="purchase-button mr-3 mt-4 no-border">
                                Save
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer class="mr-1 mb-0 w-100 mt-5 pl-4 pr-2" id="footer"/>
        </div>
    </div>
    <Loading v-if="loaded == false"/>
</div>
</template>

<script>
import ProgressBar from 'progressbar.js'
import Footer from './Footer.vue'
import Heading from './Heading.vue'
import Loading from './Loading.vue'
import axios from 'axios'

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  name: 'AdminPanel',
  components: {
      Footer,
      Heading,
      Loading
  },
  data() {
      return {
            loaded: false,
            totalDc: 0,
            orders: [],
            totalSales: 0,
            dcSales: 0,
            dailyDcSales: 0,
            meshSales: 0,
            budgetSales: 0,
            ispSales: 0,
            zalandoSales: 0,    
            premiumSales: 0,
            username: null,
            profilePicUrl: null,
            selectedSearchPool: 'zalando',
            userProxies: [],
            searchUserId: '',
            residentialMeshPageStatus: null,
            residentialPageStatus: null,
            datacenterPageStatus: null,
            shopPageStatus: null,
            residentialProductStatus: null,
            datacenterProductStatus: null,
            selectedProxyStatus: 'all',
            changeStockValue: 0,
            selectedPlanStock: 'zalando',
            changeStockInput: 0,
            premiumBalance: 0,
            budgetBalance: 0,
            meshBalance: 0
      }
  },
  methods: {
    async copyProxies(event) {
        let proxies = []

        for (let i = 0; i < this.userProxies.length; i++) {
            proxies.push(this.userProxies[i].proxy)
        }

        await navigator.clipboard.writeText(proxies.join('\n'))
        event.target.innerText = "Copied"
        await sleep(1500)
        event.target.innerText = "Copy Proxies"
    },
    async getStock(name) {
        const result = await axios.get(`${this.$apiUrl}/datacenter/stock/${name}`)
            .catch(error => {
                console.log(error.response.data)
            })

        return parseInt(result.data.stock)
    },
    async addStock(event) {
        const currentStock = await this.getStock(this.selectedPlanStock)

        event.target.disabled = true
        event.target.innerText = "Updating..."
        await axios.post(`${this.$apiUrl}/datacenter/stock/${this.selectedPlanStock}`, {
            sessionId: this.$parent.$parent.session.session_id,
            stock: parseInt(currentStock) + parseInt(this.changeStockInput)
        })

        event.target.disabled = false
        event.target.innerText = "Updated"
        await sleep(1500)
        event.target.innerText = "Add Stock"
    },
    async removeStock(event) {
        const currentStock = await this.getStock(this.selectedPlanStock)

        event.target.disabled = true
        event.target.innerText = "Updating..."
        await axios.post(`${this.$apiUrl}/datacenter/stock/${this.selectedPlanStock}`, {
            sessionId: this.$parent.$parent.session.session_id,
            stock: parseInt(currentStock) - parseInt(this.changeStockInput)
        })

        event.target.disabled = false
        event.target.innerText = "Updated"
        await sleep(1500)
        event.target.innerText = "Remove Stock"
    },
    async saveStatus(event) {
        event.target.disabled = true
        event.target.innerText = "Saving..."
        await axios.post(`${this.$apiUrl}/pages/status`, {
            sessionId: this.$parent.$parent.session.session_id,
            pageName: 'residential',
            status: this.residentialPageStatus
        })

        await axios.post(`${this.$apiUrl}/pages/status`, {
            sessionId: this.$parent.$parent.session.session_id,
            pageName: 'mesh',
            status: this.residentialMeshPageStatus
        })

        await axios.post(`${this.$apiUrl}/pages/status`, {
            sessionId: this.$parent.$parent.session.session_id,
            pageName: 'datacenter',
            status: this.datacenterPageStatus
        })

        await axios.post(`${this.$apiUrl}/pages/status`, {
            sessionId: this.$parent.$parent.session.session_id,
            pageName: 'shop',
            status: this.shopPageStatus
        })

        await axios.post(`${this.$apiUrl}/pages/status`, {
            sessionId: this.$parent.$parent.session.session_id,
            pageName: 'shop.residential',
            status: this.residentialProductStatus
        })

        await axios.post(`${this.$apiUrl}/pages/status`, {
            sessionId: this.$parent.$parent.session.session_id,
            pageName: 'shop.datacenter',
            status: this.datacenterProductStatus
        })

        event.target.disabled = false
        event.target.innerText = "Saved"
        await sleep(1500)
        event.target.innerText = "Save"
    },
    async getProxies(event) {
        event.target.disabled = true
        event.target.innerText = "Fetching..."
        let url = ''
        switch (this.selectedProxyStatus) {
            case 'all':
                url = `${this.$apiUrl}/datacenter/${this.selectedPool}`
                break;
            case 'unused':
                url = `${this.$apiUrl}/datacenter/${this.selectedPool}/${this.selectedProxyStatus}`
                break;
        }
        await axios.get(url, {
            params: {
                sessionId: this.$parent.$parent.session.session_id
            }
        })
            .catch(error => {
                console.log(error.response.data)
            })
            .then(result => {
                const proxies = result.data.proxies.join('\n')
                document.getElementById('proxy-pool').value = proxies
            })
        event.target.disabled = false
        event.target.innerText = "Show Pool"
    },
    async submitProxies(event) {
        event.target.disabled = true
        event.target.innerText = "Submitting..."

        await axios.post(`${this.$apiUrl}/datacenter`, {
            proxyType: this.selectedPool,
            sessionId: this.$parent.$parent.session.session_id,
            proxies: document.getElementById('proxy-pool').value.split('\n')
        })
            .then(() => {
                document.getElementById('proxy-pool').value = 'Added proxies\n' + document.getElementById('proxy-pool').value 
            })
            .catch(error => {
                document.getElementById('proxy-pool').value = error.response.data.error
            })
        event.target.disabled = false
        event.target.innerText = "Submit to Pool"
    },
    async getUserProxies(event) {
        const session = this.$parent.$parent.session
        event.target.disabled = true
        event.target.innerText = "Searching..."

        await axios.get(`${this.$apiUrl}/datacenter/${this.searchUserId}/${this.selectedSearchPool}`, {
            params: {
                sessionId: session.session_id
            }
        })
            .then(result => {
                if (result.data.length == 0) {
                    this.userProxies = [{proxy: 'No proxies found. Either the user has no proxies or the inputted discord id is invalid'}]
                } else {
                    this.userProxies = result.data
                }
            })
            .catch(error => {
                this.userProxies = [{proxy: error.response.data.error}]
            })
        event.target.disabled = false
        event.target.innerText = "Search"
    },
    async removeProxies(event) {
        event.target.disabled = true
        event.target.innerText = "Removing..."
        if (this.userProxies.length == 0) {
            this.userProxies = [{proxy: "Search user's proxies before removing them"}]
        }

        await axios.post(`${this.$apiUrl}/datacenter/unassign`, {
            sessionId: this.$parent.$parent.session.session_id,
            proxyType: this.selectedSearchPool,
            discordId: this.searchUserId,
            count: this.userProxies.length
        })
            .then(() => {
                this.userProxies = [{proxy: 'Successfully unassigned proxies'}]
            })
        event.target.disabled = false
        event.target.innerText = "Remove Proxies"
    },
    async reassignProxies(event) {
        event.target.disabled = true
        event.target.innerText = "Reassigning..."

        await axios.post(`${this.$apiUrl}/datacenter/reassign`, {
            sessionId: this.$parent.$parent.session.session_id,
            proxyType: this.selectedSearchPool,
            discordId: this.searchUserId
        })
            .then(() => {
                this.userProxies = [{proxy: 'Successfully reassigned proxies'}]
            })
        event.target.disabled = false
        event.target.innerText = "Reassign proxies"
    },
    async deleteProxies(event) {
        event.target.disabled = true
        event.target.innerText = "Deleting..."
        await axios.post(`${this.$apiUrl}/datacenter/delete`, {
            sessionId: this.$parent.$parent.session.session_id,
            proxyType: this.selectedPool,
            proxies: document.getElementById('proxy-pool').value.split('\n')
        })
            .then(() => {
                document.getElementById('proxy-pool').value = 'Successfully deleted proxies'
            })
            .catch(error => {
                document.getElementById('proxy-pool').value = error.response.data.error
            })
        event.target.disabled = false
        event.target.innerText = "Delete from Pool"
    },
    progressBar() {
        var totalProxies = this.totalDc
        document.getElementById('dc-count').innerHTML = ''
        var progressBar = new ProgressBar.Circle('#dc-count', {
            color: '#BDE3F4',
            trailWidth: 10,
            trailColor: '#202634',
            easing: 'easeInOut',
            duration: 1400,
            text: {
                autoStyleContainer: false
            },
            from: { color: '#BDE3F4', width: 10 },
            to: { color: '#BDE3F4', width: 10 },
            // Set default step function for all animate calls
            step: function(state, circle) {
                circle.path.setAttribute('stroke', state.color);
                circle.path.setAttribute('stroke-width', state.width);
                circle.setText(`<p class="bold progress-bar-text-main" style="margin: 0; font-size: 1.363rem;">${Math.round(totalProxies * circle.value())}</p>`)
            }
        });
        
        progressBar.text.style.textAlign = 'center'
        progressBar.text.className += ' progress-bar-text-static'
        progressBar.text.style.whiteSpace = 'nowrap'

        var premiumBalance = this.premiumBalance
        document.getElementById('premium-balance').innerHTML = ''
        var premiumProgressBar = new ProgressBar.Circle('#premium-balance', {
            color: '#BDE3F4',
            trailWidth: 10,
            trailColor: '#202634',
            easing: 'easeInOut',
            duration: 1400,
            text: {
                autoStyleContainer: false
            },
            from: { color: '#BDE3F4', width: 10 },
            to: { color: '#BDE3F4', width: 10 },
            // Set default step function for all animate calls
            step: function(state, circle) {
                circle.path.setAttribute('stroke', state.color);
                circle.path.setAttribute('stroke-width', state.width);
                circle.setText(`<p class="bold progress-bar-text-main" style="margin: 0; font-size: 1.363rem;">${parseFloat(premiumBalance * circle.value()).toFixed(2)} GB</p>`)
            }
        });
        
        premiumProgressBar.text.style.textAlign = 'center'
        premiumProgressBar.text.className += ' progress-bar-text-static'
        premiumProgressBar.text.style.whiteSpace = 'nowrap'

        var budgetBalance = this.budgetBalance
        document.getElementById('budget-balance').innerHTML = ''
        var budgetProgressBar = new ProgressBar.Circle('#budget-balance', {
            color: '#BDE3F4',
            trailWidth: 10,
            trailColor: '#202634',
            easing: 'easeInOut',
            duration: 1400,
            text: {
                autoStyleContainer: false
            },
            from: { color: '#BDE3F4', width: 10 },
            to: { color: '#BDE3F4', width: 10 },
            // Set default step function for all animate calls
            step: function(state, circle) {
                circle.path.setAttribute('stroke', state.color);
                circle.path.setAttribute('stroke-width', state.width);
                circle.setText(`<p class="bold progress-bar-text-main" style="margin: 0; font-size: 1.363rem;">${parseFloat((budgetBalance / 3) * circle.value()).toFixed(2)}GBs</p>`)
            }
        });
        
        budgetProgressBar.text.style.textAlign = 'center'
        budgetProgressBar.text.className += ' progress-bar-text-static'
        budgetProgressBar.text.style.whiteSpace = 'nowrap'

        var meshBalance = this.meshBalance
        document.getElementById('mesh-balance').innerHTML = ''
        var meshProgressBar = new ProgressBar.Circle('#mesh-balance', {
            color: '#BDE3F4',
            trailWidth: 10,
            trailColor: '#202634',
            easing: 'easeInOut',
            duration: 1400,
            text: {
                autoStyleContainer: false
            },
            from: { color: '#BDE3F4', width: 10 },
            to: { color: '#BDE3F4', width: 10 },
            // Set default step function for all animate calls
            step: function(state, circle) {
                circle.path.setAttribute('stroke', state.color);
                circle.path.setAttribute('stroke-width', state.width);
                circle.setText(`<p class="bold progress-bar-text-main" style="margin: 0; font-size: 1.363rem;">${parseFloat((meshBalance / 13) * circle.value()).toFixed(2)}GBs</p>`)
            }
        });
        
        meshProgressBar.text.style.textAlign = 'center'
        meshProgressBar.text.className += ' progress-bar-text-static'
        meshProgressBar.text.style.whiteSpace = 'nowrap'

        budgetProgressBar.animate(1)
        progressBar.animate(1);  
        premiumProgressBar.animate(1);  
        meshProgressBar.animate(1);
    }
  },
  mounted: async function() {
    // check session
    await this.$parent.$parent.checkSession()
    const session = this.$parent.$parent.session

    if (!session.admin) {
        this.$router.push('dashboard')
    } else if (!session.discord_id) {
        this.$router.push('login')
    }

    this.username = `${session.display_name}#${session.tag}`
    this.profilePicUrl = session.profile_picture_url

    let today = new Date().getTime()
    let monthStart = new Date(today).setDate(0)

    Promise.all([
        axios.get(`${this.$apiUrl}/datacenter/zalando`, {
            params: {
                sessionId: session.session_id
            }
        })
            .then(result => {
                this.totalDc += result.data.proxies.length
            }),
        axios.get(`${this.$apiUrl}/datacenter/dc`, {
            params: {
                sessionId: session.session_id
            }
        })
            .then(result => {
                this.totalDc += result.data.proxies.length
            }),
        axios.get(`${this.$apiUrl}/datacenter/isp`, {
            params: {
                sessionId: session.session_id
            }
        })
            .then(result => {
                this.totalDc += result.data.proxies.length
            }),
        axios.get(`${this.$apiUrl}/datacenter/daily_dc`, {
            params: {
                sessionId: session.session_id
            }
        })
            .then(result => {
                this.totalDc += result.data.proxies.length
            }),
        axios.get(`${this.$apiUrl}/purchases`, {
            params: {
                sessionId: session.session_id,
                startRange: today,
                endRange: monthStart
            }
        })
            .then(result => {
                for (let i = 0; i < result.data.length; i++) {
                    this.totalSales += (result.data[i].price / 100)
                    let purchase = {}
                    purchase.quantity = parseInt(result.data[i].sku.replace(/[A-Z]+/, ''))
                    purchase.timestamp = result.data[i].timestamp
                    purchase.displayName = result.data[i].display_name
                    purchase.tag = result.data[i].tag

                    switch (result.data[i].sku.replace(/\d+/, '')) {
                        case 'ZAL':
                            purchase.name = 'Zalando Proxies'
                            this.zalandoSales += purchase.quantity
                            break;
                        case 'ISP': 
                            purchase.name = 'ISP Proxies'
                            this.ispSales += purchase.quantity
                            break;
                        case 'DC':
                            purchase.name = 'Datacenter Proxies'
                            this.dcSales += purchase.quantity
                            break;
                        case 'MESH':
                            purchase.name = 'Residential Mesh'
                            this.meshSales += purchase.quantity
                            break;
                        case 'BUGT':
                            purchase.name = 'Residential Budget'
                            this.budgetSales += purchase.quantity
                            break;
                        case 'PREM':
                            purchase.name = 'Residential Premium'
                            this.premiumSales += purchase.quantity
                            break
                        case 'DAIL':
                            purchase.name = 'Daily DCs'
                            this.dailyDcSales += purchase.quantity
                            break
                    }

                    this.orders.push(purchase)
                }
                this.orders = this.orders.reverse()
            }),
        axios.get(`${this.$apiUrl}/pages/status`)
            .then(result => {
                this.residentialMeshPageStatus = result.data['mesh']
                this.residentialPageStatus = result.data.residential 
                this.datacenterPageStatus = result.data.datacenter 
                this.shopPageStatus = result.data.shop
                this.residentialMeshProductStatus = result.data['shop.mesh']
                this.residentialProductStatus = result.data['shop.residential']
                this.datacenterProductStatus = result.data['shop.datacenter']
            }),
        axios.get(`${this.$apiUrl}/residential/balance/mesh`)
            .then(result => {
                this.meshBalance = result.data.balance
            }),
        axios.get(`${this.$apiUrl}/residential/balance/premium`)
            .then(result => {
                this.premiumBalance = result.data.balance
            }),
        axios.get(`${this.$apiUrl}/residential/balance/budget`)
            .then(result => {
                this.budgetBalance = result.data.balance
            })
    ])
        .then(() => {
            this.loaded = true
            this.progressBar()
        })
        .catch(error => {
            console.log(error)
        })
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px; 
  background-color: rgba(0,0,0,0);
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical {
    background: rgb(71, 71, 71);
    -webkit-border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    min-height: 30px;

}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgb(48, 48, 48); 
  -webkit-border-radius: 100px;
}

@media (min-width: 992px) {
    #total-proxy-box {
        max-height: 325px;
    }
    #show-div {
        max-height: 325px;
    }
}

#user-proxy-jumbo {
    height: 130px;
    max-height: 130px;
}

textarea {
    background: #11151E;
    overflow: auto;
    resize: none;
    color: inherit;
}

#order-box {
    max-height: 325px;
    overflow: auto;
}

h2 {
    font-size: 1.75rem;
}

.progress-bar-container {
    position: relative;
}

.text-area {
    background: #11151E;
    overflow: auto;
}

.text-area-container {
    flex: 1 1 auto;
}

.min-height-300 {
    min-height: 200px;
}

#webhook {
    background-color: #202634;
    font-size: 0.9375rem;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
    color: #BDE3F4;
}

#webhook:focus {
    color: #BDE3F4;
}

#change-stock-input {
    background-color: #202634;
    font-size: 0.9375rem;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
    color: #BDE3F4;
}

#change-stock-input:focus {
    color: #BDE3F4;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mb-2-1 {
    margin-bottom: 2.1rem;
}

.dark-text {
    color: #6D7B88
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.dropdown {
    background-color: #202634;
    padding: 10px;
}

.no-border {
    border:0px;
    outline:0px;
}

#account-heading {
    padding-left: 15px;
}

.col-2-5 {
    flex: 0 0 20.83%;
    max-width: 20.83%;
}

.col-3-5 {
    flex: 0 0 33.333333%;
    max-width: 0 0 33.333333%;
}

@media (max-width: 1440px) {
   .col-3-5 {
        flex: 0 0 45.83%;
        max-width: 45.83%;
    } 
}

@media (max-width: 991px) {
    .col-2-5 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    #profile-pic {
        max-height: 200px;
        max-width: 200px;
    }

    .progress-box {
        font-size: 0.5rem;
    }

    .col-3-5 {
        flex: 0 0 100%;
        max-width: 100%;
    } 
}

.nav-item:hover i::before {
    color: #50FFF9;
}

.active-item {
    color: #BDE3F4 !important;
}

.nav-item:hover > a {
    color: #BDE3F4
}

.nav-item > a {
    color: #191E29;
}

.rounded-corner {
    border-radius: 5px;
}

.product-box {
    background-color: #191E29;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.purchase-button {
    background-color: #BDE3F4;
    color: #191E29;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: clamp(5px, 7%, 50px);
    padding-right: clamp(5px, 7%, 50px);
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 5px;
}

.purchase-button:hover {
    background-color: #aad1e3;
    box-shadow: 5px 0px 10px 5px #00000054 !important;
}

#footer > a {
    margin-left: 2.5%;
    margin-right: 2.5%;
}

#menu-bar {
    background-color: #0D0A17;
}

.cell {
    font-size: 0.7rem;
    color: #C2D7E8CC;
    word-wrap: break-word;
}

#profile-container {
    border-radius: 10px;
    background-color: #191E29;
    padding: 8px 15px;
    margin-bottom: 10px;
}

#profile-pic {
    border-radius: 50%;
}

@media (min-width: 768px) {
    #menu-bar {
        max-width: 350px;
    }
}

@media (max-width: 767px) {
    #header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    #footer {
        margin-top: 8rem;
    }

    #profile-container {
        display: none;
    }

    .col-3-5 {
        flex: 0 0 100%;
        max-width: 100%;
    } 
}

/* Custom switch bootstrap */
.custom-control-label::before {
    background-color: #BDE3F4;
    border: none;
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: #BDE3F4;
    border: none;
}

.custom-switch .custom-control-label::after {
    background-color: #0E1217;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #0E1217;
}
</style>
