<template>
    <div class="row">
        <div class="d-flex w-100 align-items-end">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0 flex-row justify-content-end w-100 align-items-center" id="menu-items">
                <li class="nav-item">
                    <router-link class="nav-link semi-bold" to="/tos">
                        Terms of Service
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link semi-bold" to="/privacypolicy">
                        Privacy Policy
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link semi-bold" to='/refunds'>
                        Refund Policy
                    </router-link>
                </li>
                <li class="nav-item" id="dash-button">
                    <router-link class="nav-link semi-bold" to="/faq">
                        FAQ                   
                    </router-link>
                </li>
            </ul>
            <router-view/>
        </div> 
    </div>
</template>

<script>

export default {
    name: 'Footer',
}
</script>

<style scoped>
.navbar-brand > svg {
    height: 1.375rem;
    width: auto;
}

.dark-text {
    color: #6D7B88;
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.dropdown {
    background-color: #202634;
    padding: 10px;
}

.nav-item > a, .nav-item > i {
    color: #BDE3F4;
    font-size: 14px;
}

.nav-item > a {
    white-space: nowrap;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(189,227,244, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

#menu-bar {
    background-color: #0D0A17;
}

#header {
    margin-left: 11%;
}

@media (min-width: 768px) {
    #menu-items > li {
        margin-right: 40px;
    }

    .purchase-button {
        margin-left: 30px;
    }
}

@media (max-width: 767px) {
    #menu-items {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        padding-right: 0 !important;
    }

    #header {
        display: none;
    }
}
</style>