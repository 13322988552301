<template>
    <div id="contact">
        <div id="contact-jumbo" class="col-12 jumbotron product-box rounded-corner"> 
            <h2>Get in contact with us!</h2>
            <button class="no-border purchase-button mr-4 mt-4">
                <a class="nav-link dark-text contact-text no-hover" href="https://discord.gg/rMAKvENqJ8">Discord</a>
            </button>
            <button class="no-border purchase-button mt-4">
                <a class="nav-link dark-text contact-text no-hover" href="https://twitter.com/InfinitySupply_">Twitter</a>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact'
}
</script>

<style>
.purchase-button {
    background-color: #BDE3F4;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
}

#contact-jumbo > h2 {
    font-weight: 800;
    font-size: 1.75rem;
}

.contact-text {
    font-weight: 600;
    font-size: 0.875rem;
}

.product-box {
    background-color: #191E29;
    padding-top: 25px;
    padding-bottom: 20px;
}

.dark-text {
    color: #1A151D
}

@media (max-width: 767px) {
    #contact-jumbo {
        display: flex;
        flex-direction: column;
    }

    #contact-jumbo > * {
        margin-top: 15px;
    }

    #contact-jumbo > button {
        width: 100%;
    }

    #contact-jumbo > h2 {
        text-align: center;
    }

    #contact {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .product-box {
        padding-top: 5px;
        padding-bottom: 35px;
    }

    #contact-jumbo > h2 {
        font-size: 1.438rem;
    }
}
</style>