<template>
  <div id="app" class="h-100">
      <router-view/>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import axios from 'axios'
import crypto from 'crypto'

export default {
  name: 'App',
  methods: {
    async checkSession() {
        await axios.get(`${this.$apiUrl}/sessions/${Cookies.get('sessionId')}`)
          .then(result => {
            this.session = result.data.session
          })
          .catch(async(error) => {
            if (!error.message.includes('404')) {
              console.log(error.message)
            }
            Cookies.set('sessionId', crypto.randomBytes(8).toString('hex'))
            await axios.post(`${this.$apiUrl}/sessions`, { 
              'sessionId': Cookies.get('sessionId'), 
              'redirectUrl': window.location.href
            })
          })
    }
  },
  data() {
    return {
      session: {}
    }
  }
}
</script>

<style scoped>
#app {
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

