<template>
<div>
<div class="accordion pt-4" id="accordionExample">
    <h1 class="mb-4">F.A.Q</h1>
  <div class="card mb-3 rounded-corner dark-background">
    <div class="card-header px-0 pt-1" id="headingOne">
      <h2 class="mb-0">
        <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
            <div class="heading-bottom-border bold tiny-title">
                <i class="bi-dash-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                <i class="bi-plus-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                Where can I find my proxies after a purchase?
            </div>
        </button>
      </h2>
    </div>
    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div class="card-body tiny-title pt-0">
            After a successful purchase, you'll find your proxies in your User Dashboard.
      </div>
    </div>
  </div>

  <div class="card mb-3 rounded-corner dark-background">
    <div class="card-header px-0 pt-1" id="headingTwo">
      <h2 class="mb-0">
        <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            <div class="heading-bottom-border bold tiny-title">
                <i class="bi-dash-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                <i class="bi-plus-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                How long do my proxies last?
            </div>
        </button>
      </h2>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body tiny-title pt-0">
        Unless stated otherwise, your Datacenter Proxys will expire after 30 Days.
        For Residential plans, your Proxys will expire after 30 days or until your bought amount is emptied.      
      </div>
    </div>
  </div>

  <div class="card mb-3 rounded-corner dark-background">
    <div class="card-header px-0 pt-1" id="headingThree">
      <h2 class="mb-0">
        <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            <div class="heading-bottom-border bold tiny-title">
                <i class="bi-dash-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                <i class="bi-plus-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                Where can I see my data usage for my residential plan?
            </div>
        </button>
      </h2>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body tiny-title pt-0">
            All this can be found in a nice overview of your different proxy plans, in your User Dashboard.
      </div>
    </div>
  </div>

  <div class="card mb-3 rounded-corner dark-background">
    <div class="card-header px-0 pt-1" id="headingFour">
      <h2 class="mb-0">
        <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            <div class="heading-bottom-border bold tiny-title">
                <i class="bi-dash-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                <i class="bi-plus-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                Where do your residential proxies work on?
            </div>
        </button>
      </h2>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
      <div class="card-body tiny-title pt-0">
            Infinity's Residential Proxys can be used on a wide Range of Stores, for example: <br> <br>
            Footsites EU <br>
            Footsites US <br>
            Mesh <br>
            Nike SNKRS <br>
            OFF-White <br>
            Shopify <br>
            Supreme <br>
            Snipes <br>
            Solebox <br>
            Onygo <br>
            Courir <br>
            and many more.
      </div>
    </div>
  </div>

  <div class="card mb-3 rounded-corner dark-background">
    <div class="card-header px-0 pt-1" id="headingFive">
      <h2 class="mb-0">
        <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
            <div class="heading-bottom-border bold tiny-title">
                <i class="bi-dash-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                <i class="bi-plus-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                In which countries can I generate my proxies?
            </div>
        </button>
      </h2>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
      <div class="card-body tiny-title pt-0">
        We support a wide range of countries, you can select them while generating the proxies in your user dashboard.      
      </div>
    </div>
  </div>

  <div class="card mb-3 rounded-corner dark-background">
    <div class="card-header px-0 pt-1" id="headingSix">
      <h2 class="mb-0">
        <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
            <div class="heading-bottom-border bold tiny-title">
                <i class="bi-dash-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                <i class="bi-plus-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                Do you offer site specific pools?
            </div>
        </button>
      </h2>
    </div>
    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
      <div class="card-body tiny-title pt-0">
        Yes we offer Site specific pools for both residential and datacenter plans. <br>
        Including a Premium Pool, Mesh Pool, Low Budget Pool, which comes with our Residential Plans. <br>
        For Datacenter Proxys, we offer optimized Pools for certain Sites e.g. Footlocker or Zalando. <br>
      </div>
    </div>
  </div>

  <div class="card mb-3 rounded-corner dark-background">
    <div class="card-header px-0 pt-1" id="headingSeven">
      <h2 class="mb-0">
        <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
            <div class="heading-bottom-border bold tiny-title">
                <i class="bi-dash-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                <i class="bi-plus-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                In which locations are your datacenter proxies hosted?
            </div>
        </button>
      </h2>
    </div>
    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
      <div class="card-body tiny-title pt-0">
        Our datacenter proxies are hosted in european cities like Frankfurt, to ensure our customers the best speed to different eu stores.
      </div>
    </div>
  </div>

  <div class="card mb-3 rounded-corner dark-background">
    <div class="card-header px-0 pt-1" id="headingEight">
      <h2 class="mb-0">
        <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
            <div class="heading-bottom-border bold tiny-title">
                <i class="bi-dash-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                <i class="bi-plus-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                On which Sites work your datacenter proxies?
            </div>
        </button>
      </h2>
    </div>
    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
      <div class="card-body tiny-title pt-0">
            We offer special packages for certain sites.Special Websites need special Proxies. This also explains some price differences. More Information about an working sitelist can be found on the purchase page and/or in our discord server.       </div>
    </div>
  </div>

  <div class="card mb-3 rounded-corner dark-background">
    <div class="card-header px-0 pt-1" id="headingNine">
      <h2 class="mb-0">
        <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
            <div class="heading-bottom-border bold tiny-title">
                <i class="bi-dash-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                <i class="bi-plus-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                Do you offer replacements or refunds?
            </div>
        </button>
      </h2>
    </div>
    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
      <div class="card-body tiny-title pt-0">
        Please refer to out terms/refund policy for further Details. We dont offer Replacements and Refunds.
      </div>
    </div>
  </div>

  <div class="card mb-3 rounded-corner dark-background">
    <div class="card-header px-0 pt-1" id="headingTen">
      <h2 class="mb-0">
        <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
            <div class="heading-bottom-border bold tiny-title">
                <i class="bi-dash-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                <i class="bi-plus-circle mr-2" style="font-size: 1.5rem; color: #BDE3F4;"></i>                           
                How can i get in touch with you, if i am looking for an partnership or bulkdeal or groupbuys?
            </div>
        </button>
      </h2>
    </div>
    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
      <div class="card-body tiny-title pt-0">
        You can get in touch with us via our mail: contact@infinity-supply.eu <br>
        Or join our Discord. <br>
        We wont response to any twitter dms, please only use the mail. <br>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
h1 {
    font-size: 2.8125rem !important;
    font-weight: 900 !important;
}

#accordionExample {
    padding-left: calc(0.5rem + 5%);
    padding-right: 7%;
}

button {
    color: #BDE3F4 !important;
}
button:focus {
    outline: none !important;
    box-shadow: none !important;
}

button[aria-expanded=true] .heading-bottom-border {
    border-bottom-color: #bde3f426 !important;
    border-bottom-width: 0.02px !important;
    border-bottom-style: solid !important;
    padding-bottom: 1.25rem !important;
    -webkit-transition: all 0.25s linear;
    -moz-transition: all 0.25s linear;
    -o-transition: all 0.25s linear;
    -ms-transition: all 0.25s linear;
    transition: all 0.25s linear;
}

button[aria-expanded=false] .heading-bottom-border {
    border-bottom-style: none !important;
    -webkit-transition: all 0.25s linear;
    -moz-transition: all 0.25s linear;
    -o-transition: all 0.25s linear;
    -ms-transition: all 0.25s linear;
    transition: all 0.25s linear;
}

button[aria-expanded=true] .bi-plus-circle {
    display: none;
}

button[aria-expanded=false] .bi-dash-circle {
    display: none;
}

.dark-background {
    background-color: #191E29 !important;
}
</style>