<template>
<div class="" id="tag-line-container"> 
    <div class="w-100 pb-5" id ="tag-line">
        <h1 id="sub-title" class="medium-title mb-1">The only supply you will ever need</h1>
        <p id="purpose" class="mt-3">With the strive to perfection Infinity Supply brings you the proxies that you always wanted to have! Start your engine and experience the best residential and datacenter proxy experience you have ever think of!</p>
    </div>
</div>
</template>

<script>
export default {
    name: "CatchLine"
}
</script>

<style>
#sub-title {
    font-weight: 800;
}

#purpose {
    font-weight: 400;
    font-size: 1.3rem;
}

@media (max-width: 767px) {
    #tag-line-container {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    
    #purpose {
        width: 90% !important;
    }
}

@media (max-width: 620px) {
    #purpose {
        font-size: 0.875rem !important;
    }
}
</style>