<template>
<div id="privacy-text-container">
<h1 class="pt-5 pb-5">Privacy Policy</h1>
<h5 class="bold">Datenschutzrichtlinien</h5>
Ihre Privatsphäre ist für uns wichtig. INFINITY SUPPLY respektiert Ihre Privatsphäre in Bezug auf alle Informationen, die wir über unsere Website
https://www.infinity-supply.eu von Ihnen sammeln.
<h4 class="bold my-4">1. Informationen, die wir sammeln</h4>

Gerätedaten: Wir können auch Daten über das Gerät erfassen, das Sie für den Zugriff auf unsere Website verwenden. Diese Daten können den Gerätetyp, das Betriebssystem, eindeutige Gerätekennungen, Geräteeinstellungen und geografische Standortdaten umfassen. Was wir sammeln, kann von den individuellen Einstellungen Ihres Geräts und Ihrer Software abhängen. Wir empfehlen, die Richtlinien Ihres Geräteherstellers oder Softwareanbieters zu überprüfen, um zu erfahren, welche Informationen sie uns zur Verfügung stellen.
<br> <br>
Persönliche Informationen
<br>
Wir fragen Sie möglicherweise nach persönlichen Informationen, wie z. B:
<br>
- Name
<br>
- E-Mail
<br>
- Adresse/Postanschrift
<br>
- Informationen zur Bezahlung verarbeitet durch Stripe
<br>

<h4 class="bold my-4">2. Rechtsgrundlagen für die Verarbeitung</h4>
Wir verarbeiten Ihre persönlichen Daten auf rechtmäßige, faire und transparente Weise. Wir sammeln und verarbeiten Informationen über Sie nur, wenn wir rechtliche Grundlagen dafür haben. Diese Rechtsgrundlagen sind abhängig von den von Ihnen genutzten Diensten und der Art und Weise, wie Sie diese nutzen, d.h. wir erheben und verwenden Ihre Daten nur, wenn:
<br><br>
- es für die Erfüllung eines Vertrages notwendig ist, dessen Vertragspartei Sie sind, oder um auf Ihren Wunsch hin Schritte vor dem Abschluss eines solchen Vertrages zu unternehmen (z. B. wenn wir eine von Ihnen angeforderte Dienstleistung erbringen);
<br><br>
- es zur Erfüllung eines berechtigten Interesses dient (das nicht durch Ihre Datenschutzinteressen überlagert wird), z. B. für Forschung und Entwicklung, zur Vermarktung und Förderung unserer Dienstleistungen und zum Schutz unserer gesetzlichen Rechte und Interessen;
<br><br>
- Sie uns eine Einwilligung für einen bestimmten Zweck erteilen (z.B. könnten Sie zustimmen, dass wir Ihnen unseren Newsletter zusenden);
<br><br>
- oder wir müssen Ihre Daten verarbeiten, um einer gesetzlichen Verpflichtung nachzukommen.
<br><br>
Wenn Sie der Verwendung Ihrer Daten für einen bestimmten Zweck zustimmen, haben Sie das Recht, Ihre Meinung jederzeit zu ändern (dies hat jedoch keinen Einfluss auf eine bereits erfolgte Verarbeitung). Wir bewahren personenbezogene Daten nicht länger als nötig auf. Während wir diese Informationen aufbewahren, schützen wir sie mit wirtschaftlich vertretbaren Mitteln, um Verlust und Diebstahl sowie unbefugten Zugriff, Offenlegung, Kopieren, Verwendung oder Änderung zu verhindern. Wir weisen jedoch darauf hin, dass keine Methode der elektronischen Übertragung oder Speicherung 100% sicher ist und wir keine absolute Datensicherheit garantieren können. Falls erforderlich, können wir Ihre personenbezogenen Daten zur Erfüllung einer gesetzlichen Verpflichtung oder zum Schutz Ihrer lebenswichtigen Interessen oder der lebenswichtigen Interessen einer anderen natürlichen Person aufbewahren.

<h4 class="bold my-4">3. Sammlung und Verwendung von Informationen</h4>
Wir können Informationen für die folgenden Zwecke erheben, aufbewahren, verwenden und offenlegen, und personenbezogene Daten werden nicht in einer Weise weiterverarbeitet, die mit diesen Zwecken unvereinbar ist: für interne Aufzeichnungen und Verwaltungszwecke sowie zur Erfüllung unserer gesetzlichen Verpflichtungen und zur Beilegung etwaiger Streitigkeiten, die wir haben könnten.

<h4 class="bold my-4">4. Offenlegung von persönlichen Informationen an Dritte</h4>
Wir können persönliche Informationen an Dritte weitergeben, um Daten zu sammeln und zu verarbeiten.

<h4 class="bold my-4">5. Internationale Übermittlung von personenbezogenen Daten</h4>
Die von uns erfassten personenbezogenen Daten werden in Großbritannien und den Vereinigten Staaten oder dort, wo wir oder unsere Partner, verbundenen Unternehmen und Drittanbieter Einrichtungen unterhalten, gespeichert und verarbeitet. Indem Sie uns Ihre personenbezogenen Daten zur Verfügung stellen, stimmen Sie der Weitergabe an diese Drittparteien in Übersee zu.

Wir stellen sicher, dass jede Übertragung personenbezogener Daten aus Ländern des Europäischen Wirtschaftsraums (EWR) in Länder außerhalb des EWR durch angemessene Schutzmaßnahmen geschützt wird, beispielsweise durch die Verwendung von Standard-Datenschutzklauseln, die von der Europäischen Kommission genehmigt wurden, oder durch die Verwendung von verbindlichen Unternehmensregeln oder anderen rechtlich anerkannten Mitteln.

Wenn wir personenbezogene Daten aus einem Nicht-EWR-Land in ein anderes Land übertragen, erkennen Sie an, dass Dritte in anderen Gerichtsbarkeiten möglicherweise nicht ähnlichen Datenschutzgesetzen unterliegen wie die in unserer Gerichtsbarkeit. Es bestehen Risiken, wenn ein solcher Dritter Handlungen oder Praktiken vornimmt, die gegen die Datenschutzgesetze in unserer Rechtsordnung verstoßen, und dies könnte bedeuten, dass Sie nicht in der Lage sind, Rechtsmittel nach den Datenschutzgesetzen unserer Rechtsordnung einzulegen.

<h4 class="bold my-4">6. Ihre Rechte und die Kontrolle Ihrer persönlichen Daten</h4>

Wahlmöglichkeit und Zustimmung: Indem Sie uns personenbezogene Daten zur Verfügung stellen, erklären Sie sich damit einverstanden, dass wir Ihre personenbezogenen Daten in Übereinstimmung mit dieser Datenschutzrichtlinie sammeln, aufbewahren, verwenden und weitergeben. Wenn Sie unter 16 Jahre alt sind, müssen Sie über die Erlaubnis Ihrer Eltern oder Ihres Erziehungsberechtigten für den Zugriff und die Nutzung der Website verfügen und diese (Ihre Eltern oder Ihr Erziehungsberechtigter) haben der Bereitstellung Ihrer personenbezogenen Daten an uns zugestimmt. Sie sind nicht verpflichtet, uns personenbezogene Daten zur Verfügung zu stellen, wenn Sie dies jedoch nicht tun, kann dies Ihre Nutzung dieser Website oder der auf oder über sie angebotenen Produkte und/oder Dienstleistungen beeinträchtigen.
<br><br>
Informationen von Drittparteien: Wenn wir persönliche Informationen über Sie von Dritten erhalten, werden wir diese gemäß dieser Datenschutzrichtlinie schützen. Wenn Sie eine dritte Partei sind, die uns persönliche Informationen über eine andere Person zur Verfügung stellt, sichern Sie zu, dass Sie die Zustimmung dieser Person haben, die persönlichen Informationen an uns weiterzugeben.
Einschränken: Sie können sich dafür entscheiden, die Erfassung oder Verwendung Ihrer personenbezogenen Daten einzuschränken. Wenn Sie zuvor zugestimmt haben, dass wir Ihre personenbezogenen Daten für Direktmarketingzwecke verwenden, können Sie Ihre Meinung jederzeit ändern, indem Sie uns unter Verwendung der unten stehenden Angaben kontaktieren. Wenn Sie uns bitten, die Verarbeitung Ihrer personenbezogenen Daten einzuschränken oder zu begrenzen, werden wir Sie darüber informieren, wie sich die Einschränkung auf Ihre Nutzung unserer Website oder unserer Produkte und Dienstleistungen auswirkt.
Zugang und Datenübertragbarkeit: Sie können Einzelheiten zu den personenbezogenen Daten anfordern, die wir über Sie gespeichert haben. Sie können eine Kopie der persönlichen Daten anfordern, die wir über Sie gespeichert haben. Wenn möglich, werden wir diese Informationen im CSV-Format oder einem anderen leicht lesbaren Maschinenformat zur Verfügung stellen. Sie können jederzeit verlangen, dass wir die persönlichen Daten, die wir über Sie gespeichert haben, löschen. Sie können auch verlangen, dass wir diese personenbezogenen Daten an einen anderen Dritten übertragen.
Berichtigung: Wenn Sie der Meinung sind, dass Informationen, die wir über Sie gespeichert haben, ungenau, veraltet, unvollständig, irrelevant oder irreführend sind, kontaktieren Sie uns bitte unter den unten angegebenen Kontaktdaten. Wir werden angemessene Schritte unternehmen, um alle Informationen zu korrigieren, die sich als ungenau, unvollständig, irreführend oder veraltet erweisen.
Benachrichtigung über Datenverstöße: Wir werden die auf uns anwendbaren Gesetze in Bezug auf jede Datenverletzung einhalten.
<br><br>
Reklamationen: Wenn Sie der Meinung sind, dass wir gegen ein einschlägiges Datenschutzgesetz verstoßen haben, und eine Beschwerde einreichen möchten, kontaktieren Sie uns bitte unter Verwendung der nachstehenden Angaben und teilen Sie uns alle Einzelheiten zu dem angeblichen Verstoß mit. Wir werden Ihre Beschwerde umgehend untersuchen und Ihnen schriftlich antworten, wobei wir das Ergebnis unserer Untersuchung und die Schritte, die wir zur Behandlung Ihrer Beschwerde unternehmen werden, darlegen. Sie haben auch das Recht, sich in Bezug auf Ihre Beschwerde an eine Aufsichtsbehörde oder eine Datenschutzbehörde zu wenden.
Abbestellen: Um sich von unserer E-Mail-Datenbank abzumelden oder Mitteilungen (einschließlich Marketingmitteilungen) abzubestellen, wenden Sie sich bitte an uns unter Verwendung der unten stehenden Angaben oder verwenden Sie die in der Mitteilung bereitgestellten Abmeldemöglichkeiten.
<br><br>
<h4 class="bold my-4">7. Cookies:</h4>
Wir verwenden "Cookies", um Informationen über Sie und Ihre Aktivitäten auf unserer Website zu sammeln. Ein Cookie ist ein kleiner Datensatz, den unsere Website auf Ihrem Computer speichert und bei jedem Ihrer Besuche abruft, damit wir verstehen können, wie Sie unsere Website nutzen. Dies hilft uns, Ihnen Inhalte basierend auf den von Ihnen angegebenen Präferenzen zu präsentieren. Bitte lesen Sie unsere Cookie-Richtlinie für weitere Informationen.

<h4 class="bold my-4">8. Geschäftsübertragungen</h4>
Falls wir oder unsere Vermögenswerte erworben werden, oder in dem unwahrscheinlichen Fall, dass wir aus dem Geschäft gehen oder in Konkurs gehen, würden wir Daten zu den Vermögenswerten zählen, die an Parteien übertragen werden, die uns erwerben. Sie erkennen an, dass es zu solchen Übertragungen kommen kann und dass die Parteien, die uns erwerben, Ihre personenbezogenen Daten weiterhin gemäß dieser Richtlinie verwenden können.

<h5 class="bold my-4">Datenschutzbeauftragter:</h5>
Zu fragen oder Anträgen zur Löschung von Data kontaktieren Sie:
<br><br>
Name: Clemens S
<br>
Telefon: auf Nachfrage
<br>
E-Mail: contact@infinity-supply.eu

<h1 class="pt-5 my-5">Imprint</h1>
<h5 class="bold my-4">Angaben gemäß § 5 TMG</h5>

Infinity Supply, Stuttgart, Germany
<br><br>
<div class="d-flex"> <div class="mr-1 bold">Vertreten durch:</div> Clemens S &#38; Dennis L </div>

<h5 class="bold my-4">Kontakt</h5>

Telefon: auf Anfrage
<br>
E-Mail: contact@infinity-supply.eu

<h5 class="bold my-4">Umsatzsteuer-ID</h5>
Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: Wird nachgereicht 
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
h1 {
    font-size: 2.8125rem !important;
    font-weight: 900 !important;
}

#privacy-text-container {
    font-size: 1.25rem;
    padding-left: calc(0.5rem + 5%);
    padding-right: 7%;
}
</style>