<template>
  <div class="col" id="dc-table-container">
    <div v-if="loaded" class="h-100">
      <div v-if="pageStatus" class="h-100">
        <div
          class="justify-content-between d-flex row h-100 w-100 pl-sm-0 mr-0"
        >
          <div class="w-100">
            <Heading
              :customStyles="'margin-right: 2px;'"
              :pageTitle="'Manage Datacenter Proxies'"
              :username="username"
              :profilePicLink="profilePicUrl"
              class="mb-2"
            />
            <div>
              <div
                class="d-flex justify-content-between w-100 mt-sm-5 mt-4 mb-4 pb-3"
              >
                <div>
                  <p class="select-label dark-text mb-1">Switch plans</p>
                  <select
                    v-model="activeList"
                    class="dropdown no-border dark-text px-3"
                  >
                    <option>Infinity Zalando DCs</option>
                    <option>Infinity ISP Proxies</option>
                    <option>Infinity DCs</option>
                    <option>Infinity Daily DCs</option>
                  </select>
                </div>
                <div class="mobile-optional">
                  <p class="select-label dark-text mb-1">
                    Choose website for test
                  </p>
                  <select
                    id="test-site-select"
                    class="dropdown no-border dark-text px-3"
                  >
                    <option value="http://www.footlocker.de/">
                      Footlocker DE
                    </option>
                    <option value="http://www.zalando.com/">Zalando</option>
                    <option value="http://www.snipes.com/">Snipes</option>
                  </select>
                </div>
              </div>
              <div
                v-if="activeList == 'Infinity Zalando DCs'"
                class="box-background rounded-corner px-3 pt-3 pb-4 w-100 table-container"
                id="zalando"
              >
                <div class="row mx-1 pb-2 mb-3" id="top-row">
                  <div class="col-12 col-lg-4 px-0 pb-1 cell">Proxies</div>
                  <div class="col-4 px-0 cell mobile-optional">Valid Until</div>
                  <div class="col-2 px-0 cell mobile-optional">Status</div>
                  <div class="col-2 p-0 cell mobile-optional">Test Speed</div>
                </div>
                <div
                  v-for="proxy in zalandoProxies"
                  :key="proxy.proxy"
                  class="row mx-1 mb-1"
                >
                  <div class="col-12 col-lg-4 px-0 cell">
                    {{ proxy.proxy }}
                  </div>
                  <div class="col-4 px-0 cell mobile-optional">
                    {{ proxy.expirationTime }}
                  </div>
                  <div class="col-2 px-0 cell mobile-optional">Active</div>
                  <div class="col-2 px-0 cell mobile-optional">
                    {{ proxy.speed }}
                  </div>
                </div>
              </div>

              <div
                v-if="activeList == 'Infinity ISP Proxies'"
                class="box-background rounded-corner px-3 pt-3 pb-4 w-100 table-container"
                id="isp"
              >
                <div class="row mx-1 pb-2 mb-3" id="top-row">
                  <div class="col-12 col-lg-4 px-0 pb-1 cell">Proxies</div>
                  <div class="col-4 px-0 cell mobile-optional">Valid Until</div>
                  <div class="col-2 px-0 cell mobile-optional">Status</div>
                  <div class="col-2 p-0 cell mobile-optional">Test Speed</div>
                </div>
                <div
                  v-for="proxy in ispProxies"
                  :key="proxy.proxy"
                  class="row mx-1 mb-1"
                >
                  <div class="col-12 col-lg-4 px-0 cell">
                    {{ proxy.proxy }}
                  </div>
                  <div class="col-4 px-0 cell mobile-optional">
                    {{ proxy.expirationTime }}
                  </div>
                  <div class="col-2 px-0 cell mobile-optional">Active</div>
                  <div class="col-2 px-0 cell mobile-optional">
                    {{ proxy.speed }}
                  </div>
                </div>
              </div>

              <div
                v-if="activeList == 'Infinity DCs'"
                class="box-background rounded-corner px-3 pt-3 pb-4 w-100 table-container mh-500"
                id="dc"
              >
                <div class="row mx-1 pb-2 mb-3" id="top-row">
                  <div class="col-12 col-lg-4 px-0 pb-1 cell">Proxies</div>
                  <div class="col-4 px-0 cell mobile-optional">Valid Until</div>
                  <div class="col-2 px-0 cell mobile-optional">Status</div>
                  <div class="col-2 p-0 cell mobile-optional">Test Speed</div>
                </div>
                <div
                  v-for="proxy in dcProxies"
                  :key="proxy.proxy"
                  class="row mx-1 mb-1"
                >
                  <div class="col-12 col-lg-4 px-0 cell">
                    {{ proxy.proxy }}
                  </div>
                  <div class="col-4 px-0 cell mobile-optional">
                    {{ proxy.expirationTime }}
                  </div>
                  <div class="col-2 px-0 cell mobile-optional">Active</div>
                  <div class="col-2 px-0 cell mobile-optional">
                    {{ proxy.speed }}
                  </div>
                </div>
              </div>

              <div
                v-if="activeList == 'Infinity Daily DCs'"
                class="box-background rounded-corner px-3 pt-3 pb-4 w-100 table-container mh-500"
                id="dc"
              >
                <div class="row mx-1 pb-2 mb-3" id="top-row">
                  <div class="col-12 col-lg-4 px-0 pb-1 cell">Proxies</div>
                  <div class="col-4 px-0 cell mobile-optional">Valid Until</div>
                  <div class="col-2 px-0 cell mobile-optional">Status</div>
                  <div class="col-2 p-0 cell mobile-optional">Test Speed</div>
                </div>
                <div
                  v-for="proxy in dailyDcProxies"
                  :key="proxy.proxy"
                  class="row mx-1 mb-1"
                >
                  <div class="col-12 col-lg-4 px-0 cell">
                    {{ proxy.proxy }}
                  </div>
                  <div class="col-4 px-0 cell mobile-optional">
                    {{ proxy.expirationTime }}
                  </div>
                  <div class="col-2 px-0 cell mobile-optional">Active</div>
                  <div class="col-2 px-0 cell mobile-optional">
                    {{ proxy.speed }}
                  </div>
                </div>
              </div>

              <div
                class="d-flex w-100 justify-content-end mobile-optional mt-4 pt-2 mb-5"
              >
                <button
                  v-on:click="testProxies"
                  class="no-border purchase-button mr-4 bold"
                  id="test-button"
                >
                  Test Proxies
                </button>
                <button
                  v-on:click="saveProxies"
                  class="no-border purchase-button bold"
                  id="shop-button"
                >
                  Export Proxies
                </button>
              </div>
            </div>
          </div>
          <Footer class="w-100" />
        </div>
      </div>
      <div v-else class="h-100">
        <Disabled />
      </div>
    </div>
    <Loading v-else />
  </div>
</template>

<script>
import Footer from "./Footer.vue";
import Heading from "./Heading.vue";
import Loading from "./Loading.vue";
import Disabled from "./Disabled.vue";

import axios from "axios";

export default {
  name: "Products",
  data() {
    return {
      activeList: "Infinity Zalando DCs",
      loaded: false,
      username: null,
      profilePicUrl: null,
      zalandoProxies: [],
      dcProxies: [],
      ispProxies: [],
      dailyDcProxies: [],
      testing: false,
      pageStatus: null,
      purchase: this.$route.query.purchase,
    };
  },
  components: {
    Footer,
    Heading,
    Loading,
    Disabled,
  },
  methods: {
    saveProxies() {
      let text = "";
      switch (this.activeList) {
        case "Infinity Zalando DCs":
          for (let i = 0; i < this.zalandoProxies.length; i++) {
            text += this.zalandoProxies[i].proxy + "\n";
          }
          break;
        case "Infinity ISP Proxies":
          for (let i = 0; i < this.ispProxies.length; i++) {
            text += this.ispProxies[i].proxy + "\n";
          }
          break;
        case "Infinity DCs":
          for (let i = 0; i < this.dcProxies.length; i++) {
            text += this.dcProxies[i].proxy + "\n";
          }
          break;
        case "Infinity Daily DCs":
          for (let i = 0; i < this.dailyDcProxies.length; i++) {
            text += this.dcProxies[i].proxy + "\n";
          }
          break;
      }

      let content = URL.createObjectURL(
        new Blob([text], { type: "text/plain" })
      );

      let element = document.createElement("a");
      element.setAttribute("href", content);
      element.setAttribute("download", "proxies.txt");
      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    async testProxies() {
      if (this.testing == false) {
        this.testing = true;
        document.getElementById("test-button").innerText = "Stop Testing";
      } else {
        this.testing = false;
        document.getElementById("test-button").innerText = "Test Proxies";
        return;
      }

      const testUrl = document.getElementById("test-site-select").value;
      switch (this.activeList) {
        case "Infinity Zalando DCs":
          for (let i = 0; i < this.zalandoProxies.length; i++) {
            if (this.testing == false) {
              break;
            }
            this.zalandoProxies[i].speed = "testing...";
            this.$forceUpdate();

            await axios
              .post(`${this.$apiUrl}/proxies/test`, {
                proxy: this.zalandoProxies[i].proxy,
                testUrl: testUrl,
              })
              .catch((error) => {
                console.log(error.response.data);
              })
              .then((result) => {
                if (!isNaN(result.data.speed)) {
                  this.zalandoProxies[i].speed = `${result.data.speed} MS`;
                } else {
                  this.zalandoProxies[i].speed = result.data.speed;
                }
                this.$forceUpdate();
              });
          }
          break;
        case "Infinity ISP Proxies":
          for (let i = 0; i < this.ispProxies.length; i++) {
            if (this.testing == false) {
              break;
            }
            this.ispProxies[i].speed = "testing...";
            this.$forceUpdate();

            await axios
              .post(`${this.$apiUrl}/proxies/test`, {
                proxy: this.ispProxies[i].proxy,
                testUrl: testUrl,
              })
              .catch((error) => {
                console.log(error.response.data);
              })
              .then((result) => {
                if (!isNaN(result.data.speed)) {
                  this.ispProxies[i].speed = `${result.data.speed} MS`;
                } else {
                  this.ispProxies[i].speed = result.data.speed;
                }
                this.$forceUpdate();
              });
          }
          break;
        case "Infinity DCs":
          for (let i = 0; i < this.dcProxies.length; i++) {
            if (this.testing == false) {
              break;
            }
            this.dcProxies[i].speed = "testing...";
            this.$forceUpdate();

            await axios
              .post(`${this.$apiUrl}/proxies/test`, {
                proxy: this.dcProxies[i].proxy,
                testUrl: testUrl,
              })
              .catch((error) => {
                console.log(error.response.data);
              })
              .then((result) => {
                if (!isNaN(result.data.speed)) {
                  this.dcProxies[i].speed = `${result.data.speed} MS`;
                } else {
                  this.dcProxies[i].speed = result.data.speed;
                }
                this.$forceUpdate();
              });
          }
          break;
        case "Infinity Daily DCs":
          for (let i = 0; i < this.dailyDcProxies.length; i++) {
            if (this.testing == false) {
              break;
            }
            this.dailyDcProxies[i].speed = "testing...";
            this.$forceUpdate();

            await axios
              .post(`${this.$apiUrl}/proxies/test`, {
                proxy: this.dailyDcProxies[i].proxy,
                testUrl: testUrl,
              })
              .catch((error) => {
                console.log(error.response.data);
              })
              .then((result) => {
                if (!isNaN(result.data.speed)) {
                  this.dailyDcProxies[i].speed = `${result.data.speed} MS`;
                } else {
                  this.dailyDcProxies[i].speed = result.data.speed;
                }
                this.$forceUpdate();
              });
          }
          break;
      }
      this.testing = false;
      document.getElementById("test-button").innerText = "Test Proxies";
    },
  },
  mounted: async function () {
    // check session
    await this.$parent.$parent.checkSession();
    const session = this.$parent.$parent.session;
    this.username = `${session.display_name}#${session.tag}`;
    this.profilePicUrl = session.profile_picture_url;

    // check session to make sure theyre logged in
    if (!session.discord_id) {
      this.$router.push("login");
    }

    await axios.get(`${this.$apiUrl}/pages/status`).then((result) => {
      this.pageStatus = result.data.datacenter;
    });

    if (this.pageStatus == false) {
      this.loaded = true;
      return;
    }

    Promise.all([
      axios
        .get(`${this.$apiUrl}/datacenter/${session.discord_id}/zalando`, {
          params: {
            sessionId: session.session_id,
          },
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .then((result) => {
          this.zalandoProxies = result.data;
          for (let i = 0; i < this.zalandoProxies.length; i++) {
            const date = new Date(
              parseInt(this.zalandoProxies[i].expirationTime)
            );
            this.zalandoProxies[
              i
            ].expirationTime = `${date
              .toLocaleString("default", { month: "long" })
              .slice(0, 3)} ${date.getDate()}, ${date.getFullYear()}`;
            this.zalandoProxies[i].speed = "ready for testing";
          }
        }),
      axios
        .get(`${this.$apiUrl}/datacenter/${session.discord_id}/dc`, {
          params: {
            sessionId: session.session_id,
          },
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .then((result) => {
          this.dcProxies = result.data;
          for (let i = 0; i < this.dcProxies.length; i++) {
            const date = new Date(parseInt(this.dcProxies[i].expirationTime));
            this.dcProxies[i].expirationTime = `${date
              .toLocaleString("default", { month: "long" })
              .slice(0, 3)} ${date.getDate()}, ${date.getFullYear()}`;
            this.dcProxies[i].speed = "ready for testing";
          }
        }),
      axios
        .get(`${this.$apiUrl}/datacenter/${session.discord_id}/daily_dc`, {
          params: {
            sessionId: session.session_id,
          },
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .then((result) => {
          this.dailyDcProxies = result.data;
          for (let i = 0; i < this.dailyDcProxies.length; i++) {
            const date = new Date(parseInt(this.dailyDcProxies[i].expirationTime));
            this.dailyDcProxies[i].expirationTime = `${date
              .toLocaleString("default", { month: "long" })
              .slice(0, 3)} ${date.getDate()}, ${date.getFullYear()}`;
            this.dailyDcProxies[i].speed = "ready for testing";
          }
        }),
      axios
        .get(`${this.$apiUrl}/datacenter/${session.discord_id}/isp`, {
          params: {
            sessionId: session.session_id,
          },
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .then((result) => {
          this.ispProxies = result.data;
          for (let i = 0; i < this.ispProxies.length; i++) {
            const date = new Date(parseInt(this.ispProxies[i].expirationTime));
            this.ispProxies[
              i
            ].expirationTime = `${date
              .toLocaleString("default", { month: "long" })
              .slice(0, 3)} ${date.getDate()}, ${date.getFullYear()}`;
            this.ispProxies[i].speed = "ready for testing";
          }
        }),
    ]).then(() => {
      this.loaded = true;
      switch (this.purchase) {
        case "zal":
          this.$toast.success(
            "Successfully purchased Zalando DCs. Orders are manually fulfilled so your proxies won't appear until an admin fulfills your order."
          );
          break;
        case "isp":
          this.$toast.success(
            "Successfully purchased ISP proxies. Orders are manually fulfilled so your proxies won't appear until an admin fulfills your order."
          );
          this.activeList = "Infinity ISP Proxies";
          break;
        case "dc":
          this.$toast.success(
            "Successfully purchased DC proxies. Orders are manually fulfilled so your proxies won't appear until an admin fulfills your order."
          );
          this.activeList = "Infinity DCs";
          break;
        case "dail":
          this.$toast.success(
            "Successfully purchased daily DC proxies. Orders are manually fulfilled so your proxies won't appear until an admin fulfills your order."
          );
          this.activeList = "Infinity Daily DCs";
      }
    });
  },
};
</script>

<style scoped>
.purchase-button:hover {
  background-color: #aad1e3;
  box-shadow: 5px 0px 10px 5px #00000054 !important;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical {
  background: rgb(71, 71, 71);
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 30px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgb(48, 48, 48);
  -webkit-border-radius: 100px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dark-text {
  color: #6d7b88;
}

.table-container {
  width: calc(100% - 1rem);
  max-height: 600px;
  overflow: auto;
}

.select-label {
  font-size: 0.9375rem;
}

#top-row {
  border-bottom: 1px solid;
  border-color: #bde3f426;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

button {
  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 0.9375rem;
}

#page-title {
  font-size: 2.5rem;
}

.cell {
  font-size: 0.9375rem;
}

@media (max-width: 480px) {
  .cell {
    font-size: 0.9375rem !important;
    /* font-size: 2.75vw !important; */
  }
}

.dropdown {
  background-color: #202634;
  border-radius: 5px;
  padding: 10px;
  padding-right: 3.5rem !important;
}

.no-border {
  border: 0px;
  outline: 0px;
}

#dc-table-container {
  min-height: 87vh;
}

.nav-item:hover i::before {
  color: #50fff9;
}

.active-item {
  color: #bde3f4 !important;
}

.nav-item:hover > a {
  color: #bde3f4;
}

.nav-item > a {
  color: #191e29;
}

.product-box {
  background-color: #191e29;
  padding-top: 40px;
  padding-bottom: 40px;
}

.purchase-button {
  background-color: #bde3f4;
  color: #191e29;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: clamp(5px, 7%, 60px);
  padding-right: clamp(5px, 7%, 60px);
}

#menu-bar {
  background-color: #0d0a17;
}

#profile-pic {
  border-radius: 50%;
}

@media (min-width: 768px) {
  #menu-bar {
    max-width: 350px;
  }
}

@media (max-width: 767px) {
  #header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  #profile-container {
    display: none;
  }
}
</style>
