<template>
    <nav class="navbar navbar-expand-md" id="nav">
        <div id="header">
            <a class="navbar-brand" href="/"><Logo/></a>
            <button class="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>

        <div class="collapse navbar-collapse w-100" id="navbarTogglerDemo02">
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0 flex-row justify-content-end w-100 align-items-center" id="menu-items">
            <li class="nav-item">
                <router-link class="nav-link" to="/shop">
                    <i class="bi-cart2"></i>                           
                    <span>Shop</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" to='/faq'>
                    <i class="bi-chat-square-dots"></i>                           
                    <span>FAQ</span>
                </router-link>
            </li>
            <button class="no-border purchase-button dash-button"> 
                <li class="nav-item" id="dash-button">
                    <router-link class="nav-link" id="dash-link" to="/dashboard">
                        Dashboard                    
                    </router-link>
                </li>
            </button>
          </ul>
          <router-view/>
        </div>
    </nav>
</template>

<script>
import Logo from '../Logo.vue'

export default {
    name: 'HomeNav',
    components: {
        Logo
    }
}
</script>

<style scoped>
@import 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css';
.navbar-brand > svg {
    height: 1.375rem;
    width: auto;
}

.dark-text {
    color: #6D7B88
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

#nav {
    padding-right: 7%;
    padding-top: 2rem;
}

.nav-link {
    height: 42px;
    line-height: 35px;
    display: flex;
    align-items: center;
}

.dropdown {
    background-color: #202634;
    padding: 10px;
}

.nav-link i {
    margin-bottom: 6px;
    margin-right: 8px;
}

.nav-item > a, .nav-item > i {
    color: #BDE3F4;
    font-size: 0.938rem;
    font-weight: 500;
}

.product-box {
    background-color: #191E29;
    padding-top: 40px;
    padding-bottom: 40px;
}

#dash-link {
    color: #191E29;
    font-weight: 600;
}

.purchase-button {
    background-color: #BDE3F4;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
}

.purchase-button.dash-button {
    margin-left: 40px;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(189,227,244, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

#footer > a {
    margin-left: 2.5%;
    margin-right: 2.5%;
}

#menu-bar {
    background-color: #0D0A17;
}

@media (min-width: 768px) {
    #menu-items > li {
        margin-left: 15px;
    }

    .purchase-button {
        margin-left: 30px;
    }
}

#header {
    margin-left: 5%;
}

@media (max-width: 767px) {
    #menu-items {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    #header {
        width: 100%;
        justify-content: space-between;
        display: flex;
    }

    #nav {
        padding-right: 0;
        padding-top: 1rem;
    }

    .navbar {
        padding: 0;
    }
    
    .purchase-button.dash-button {
        margin-left: 0px;
    }
}
</style>