import Vue from 'vue'
import VueRouter from 'vue-router'
import Shop from '../views/Shop.vue'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Datacenter from '../views/Datacenter.vue'
import Faq from '../views/Faq.vue'
import Login from '../views/Login.vue'
import Account from '../views/Account.vue'
import Admin from '../views/Admin.vue'
import Tos from '../views/Tos.vue'
import Refunds from '../views/Refunds.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import DatacenterAdmin from '../views/DatacenterAdmin.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/shop',
        name: 'Shop',
        component: Shop
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/datacenter',
        name: 'Datacenter',
        component: Datacenter
    },
    {
        path: '/faq',
        name: 'Faq',
        component: Faq
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/account',
        name: 'account',
        component: Account
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin
    },
    {
        path: '/tos',
        name: 'tos',
        component: Tos
    },
    {
        path: '/refunds',
        name: 'refunds',
        component: Refunds
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: PrivacyPolicy
    },
    {
        path: '/datacenter-admin',
        name: 'datacenter-admin',
        component: DatacenterAdmin
    }
]

const router = new VueRouter({
    mode: 'history',
    routes: routes
})

export default router
