<template>
    <div id="dc-speeds">
        <div id="dc-info">
            <h1 id="dc-title">Datacenter and ISPs with amazing speeds</h1>
            <div class="col-12 d-flex px-0 justify-content-between reveal">
                <div class="site-title">Footlocker</div>
                <div class="site-title">42 MS</div>
            </div>
            <div class="col-12 base-rectangle px-0 reveal"> 
                <div id="footlocker-rectangle" class="px-0">
                    <div class="gradient-container p-0 m-0">
                        <div class="radial-gradient">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex px-0 justify-content-between reveal">
                <div class="site-title">Zalando</div>
                <div class="site-title">56 MS</div>
            </div>
            <div class="col-12 base-rectangle px-0 reveal"> 
                <div id="zalando-rectangle" class="px-0">
                    <div class="gradient-container p-0 m-0">
                        <div class="radial-gradient">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex px-0 justify-content-between reveal">
                <div class="site-title">Supreme</div>
                <div class="site-title">46 MS</div>
            </div>
            <div class="col-12 base-rectangle px-0 reveal"> 
                <div id="supreme-rectangle" class="px-0">
                    <div class="gradient-container p-0 m-0">
                        <div class="radial-gradient">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex px-0 justify-content-between reveal">
                <div class="site-title">Mesh</div>
                <div class="site-title">58 MS</div>
            </div>
            <div class="col-12 base-rectangle px-0 reveal"> 
                <div id="mesh-rectangle" class="px-0">
                    <div class="gradient-container p-0 m-0">
                        <div class="radial-gradient">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex px-0 justify-content-between reveal">
                <div class="site-title">And many more</div>
                <div class="site-title">&lt;100 MS</div>
            </div>
            <div class="col-12 base-rectangle px-0 reveal"> 
                <div id="other-rectangle" class="px-0">
                    <div class="gradient-container p-0 m-0">
                        <div class="radial-gradient">
                        </div>
                    </div>
                </div>
            </div>
            <p id="dc-disclosure" class="muted-text mt-5 reveal">All speedtest where made under perfect conditions, however with a good server you will be able to hit speeds like that !</p>
        </div> 
    </div>
</template>

<script>
export default {

}
</script>

<style>
#dc-title {
    font-weight: 800;
    margin-bottom: 6%;
}

.site-title {
    font-weight: 200;
    font-size: 1.75rem;
    padding-bottom: 0.6rem;
}

#dc-disclosure {
    font-size: 1.375rem;
}

#dc-info {
    font-weight: 200 !important;
}

.base-rectangle {
    background-color: #2B2A44;
    height: 28px;
    overflow: hidden;
    margin-bottom: 20px;
}

#footlocker-rectangle {
    height: 28px;
    width: 92%;
    background-color: #7C2DFF;
}

#zalando-rectangle {
    height: 28px;
    width: 87%;
    background-color: #9559FF;
}

#supreme-rectangle {
    height: 28px;
    width: 89%;
    background-color: #AA82FF;
}

#mesh-rectangle {
    height: 28px;
    width: 85%;
    background-color: #D9B1FF;
}

#other-rectangle {
    height: 28px;
    width: 81%;
    background-color: #EEE6FF;
}

.radial-gradient {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    position: relative;
    bottom: 15px;
    right: 23px;
    /*background: radial-gradient(#8A8A8A, #080808);*/
}

.gradient-container {
    height: 28px;
    width: 28px;
    position: relative;
    left: 98%;
    border-radius: 50%;
    mix-blend-mode: color-dodge;
}

@media (max-width: 767px) {
    #dc-speeds {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

@media (max-width: 620px) {
    #dc-title {
        font-size: 1.5625rem;
        margin-bottom: 10%;
    }

    #dc-disclosure {
        font-size: 0.813rem;
    }

    .site-title {
        font-size: 1rem !important;
    }
}

</style>