<template>
    <div class="row min-vh-100">
        <DashNav :current-nav="'admin-datacenter'"/>
        <DatacenterAdminPanel class="mx-4 mt-4 mb-4 pl-md-4 pr-md-4 pt-1"/>
    </div>
</template>

<script>
import DashNav from '../components/dashboard/DashNav.vue'
import DatacenterAdminPanel from '../components/dashboard/DatacenterAdminPanel.vue'

export default {
  name: 'Dashboard',
  components: {
    DashNav,
    DatacenterAdminPanel
  }
}
</script>

