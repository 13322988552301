<template>
<div id="refund-text-container">
<h1 class="my-5">Refund Policy</h1>
INFINITY SUPPLY bietet unter keinen Umständen Rückerstattungen oder Ersatz an, daher sind alle Verkäufe endgültig. Mit dem Kauf akzeptieren Sie die Bedingungen, die Sie auf der Seite mit den Allgemeinen Geschäftsbedingungen finden.
<br><br>
Sobald eine Bestellung bestätigt wird, gilt sie als abgeschlossen, da die Lieferung unseres Produktes automatisch erfolgt, da es sich um digitale Waren handelt.
<br><br>
Wenn Sie irgendwelche Probleme mit Ihrem Kauf haben, wenden Sie sich bitte per E-Mail an contact@infinity-supply.eu, um Hilfe zu erhalten.
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
h1 {
    font-size: 2.8125rem !important;
    font-weight: 900 !important;
}

#refund-text-container {
    font-size: 1.25rem;
    padding-left: calc(0.5rem + 5%);
    padding-right: 7%;
}
</style>