<template>
<div class="row min-vh-100 view-container">
  <div id="home-nav-component" class="w-100">
    <HomeNav class="w-100"/>
  </div>
  <div id="privacy-component">
    <Privacy/>
  </div>
  <div id="footer-component" class="w-100">
      <Footer/>
  </div>
</div>
</template>

<script>
import HomeNav from '../components/home/HomeNav.vue'
import Privacy from '../components/misc/Privacy.vue'
import Footer from '../components/home/Footer.vue'

export default {
  name: 'Faq',
  components: {
    HomeNav,
    Privacy,
    Footer
  },
}
</script>

<style>
#app {
    background-color: #0F1218;
    font-family: 'Montserrat', sans-serif;
    color: #C2D7E8;
    overflow-x: hidden;
    scrollbar-width: none;
}

.view-container {
    padding-left: 1%;
    margin-left: 1%;
}

.min-vh-100 {
    min-height: 100vh;
}

body::-webkit-scrollbar {
    display: none;
}
</style>
