<template>
<aside class="col-12 col-lg-3" id="menu-bar">
    <nav class="navbar navbar-expand-lg flex-column pt-lg-5 pt-2 mt-sm-3 mb-sm-3 mb-0 mt-0" id="nav">
        <div id="header">
            <a class="navbar-brand p-0 mr-0" href="/"><Logo/></a>
            <div class="row d-flex justify-content-center mt-2 pl-2 mobile-optional flex-column">
                <h5 class="text-center kilton mb-0" id="logo-title">INFINITY</h5>
                <h6 class="text-center kilton" id="logo-sub-title">SUPPLY</h6>
            </div>
            <button class="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>

        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul class="navbar-nav mr-auto mt-md-5 pt-md-3 flex-column">
            <li class="nav-item">
                <router-link class="nav-link" :class="currentNav == 'dashboard' && 'active-item'" to="/dashboard">
                    <i class="bi-house-door" style="font-size: 1rem; color: #bde3f433;"></i>                           
                    Dashboard
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" :class="currentNav == 'datacenter' && 'active-item'" to="/datacenter">
                    <i class="bi-globe" style="font-size: 1rem; color: #bde3f433;"></i>                           
                    Datacenter
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" :class="currentNav == 'shop' && 'active-item'" to="/shop">
                    <i class="bi-cart2" style="font-size: 1.1rem; color: #bde3f433;"></i>                           
                    Shop
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" :class="currentNav == 'account' && 'active-item'" to='/account'>
                    <i class="bi-person" style="font-size: 1rem; color: #bde3f433;"></i>                           
                    My Account
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" :class="currentNav == 'faq' && 'active-item'" to='/faq'>
                    <i class="bi-chat-square-dots" style="font-size: 1rem; color: #bde3f433;"></i>                           
                    FAQ
                </router-link>
            </li>
            <li v-if="admin == true" class="nav-item">
                <router-link class="nav-link" :class="currentNav == 'admin' && 'active-item'" to='/admin'>
                    <i class="bi-person-badge" style="font-size: 1rem; color: #bde3f433;"></i>                           
                    Admin
                </router-link>
            </li>
            <li v-if="admin == true" class="nav-item">
                <router-link class="nav-link" :class="currentNav == 'admin-datacenter' && 'active-item'" to='/datacenter-admin'>
                    <i class="bi-globe2" style="font-size: 1rem; color: #bde3f433;"></i>                           
                    Datacenter Orders
                </router-link>
            </li>
            <li class="nav-item mt-5">
                <router-link v-on:click.native="logout" class="nav-link" to='/'>
                    <i class="bi bi-box-arrow-in-left" style="font-size: 1rem; color: #bde3f433;"></i>                           
                    Logout
                </router-link>
            </li>
          </ul>
          <router-view/>
        </div>
    </nav>
</aside>
</template>

<script>
import Logo from '../Logo.vue'
import axios from 'axios'

export default {
  name: 'DashNav',
  components: {
    Logo
  },
  props: {
      currentNav: String
  },
  methods: {
      async logout() {
          await axios.post(`${this.$apiUrl}/sessions/delete`, { sessionId: this.$parent.$parent.session.session_id })
          console.log('session deleted')
      }
  },
  data() {
      return {
          admin: false
      }
  },
  beforeMount: async function() {
    // check session
    await this.$parent.$parent.checkSession()
    this.admin = this.$parent.$parent.session.admin
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css';

#menu-bar {
    background-color: #39305800 !important;
    box-shadow: 5px 0px 10px 5px #00000054 !important;
    border-radius: 0px 35px 35px 0px !important;
}

#navbarTogglerDemo02 {
    width: 100%;
    padding-left: 1rem;
}

.dark-text {
    color: #6D7B88
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

#logo-title {
    font-size: 1.3rem;
}

#logo-sub-title {
    font-size: 0.9rem;
}

.dropdown {
    background-color: #202634;
    padding: 10px;
}

.no-border {
    border:0px;
    outline:0px;
}

.nav-item:hover i::before {
    color: #50FFF9;
}

.active-item {
    color: #BDE3F4 !important;
}

.active-item > i {
    color: #50FFF9 !important;
}

.nav-item:hover > a {
    color: #BDE3F4
}

.nav-item > a {
    color: #bde3f433;
}

.rounded-corner {
    border-radius: 5px;
}

.product-box {
    background-color: #bde3f433;
    padding-top: 40px;
    padding-bottom: 40px;
}

.purchase-button {
    background-color: #BDE3F4;
    color: #bde3f433;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: clamp(5px, 7%, 50px);
    padding-right: clamp(5px, 7%, 50px);
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(189,227,244, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

#footer > a {
    margin-left: 2.5%;
    margin-right: 2.5%;
}

#menu-bar {
    background-color: #0D0A17;
}

#profile-container {
    border-radius: 10px;
    background-color: #bde3f433;
    padding: 8px 15px;
    margin-bottom: 10px;
}

#profile-pic {
    border-radius: 50%;
}

@media (min-width: 992px) {
    #menu-bar {
        max-width: 265px !important;
    }
}

@media (max-width: 992px) {
    #header {
        display: flex !important;
        justify-content: space-between !important;
        width: 100% !important;
    }

    #profile-container {
        display: none;
    }

    #menu-bar {
        height: fit-content;
    }

    #navbarTogglerDemo02 {
        width: unset;
        padding-left: 0;
    }
}
</style>
