<template>
    <div class="w-100 d-flex justify-content-between">
        <h1 class="page-title bold mb-0 mt-2">{{ pageTitle }}</h1>
        <div id='profile-container' :style="customStyles" class="h-fit bold tiny-title">
            <div v-if="username && profilePicLink">
                <img class="mr-4" id="profile-pic" :src="profilePicLink">
                {{ username }}
            </div>
            <div v-else id="login-area" class="my-1">
                <a href="/login">
                    Login
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Heading',
  props: {
      username: String,
      profilePicLink: String,
      pageTitle: String,
      customStyles: String
  }
}
</script>

<style scoped>
#login-area > a {
    width: 42px;
    height: 42px;
    color: inherit !important;
}

#profile-container {
    border-radius: 10px;
    background-color: #191E29;
    padding: 6px 30px;
}

#profile-pic {
    border-radius: 50%;
    width: 42px;
    height: 42px;
}

@media (max-width: 767px) {
    #profile-container {
        display: none;
    }
}
</style>