<template>
  <div class="col" id="manage-dc-container">
    <div :hidden="loaded == false" class="h-100">
      <div class="d-flex justify-content-between flex-wrap h-100">
        <div class="d-flex flex-wrap pt-lg-2 h-fit w-100">
          <Heading
            :pageTitle="'Manage Datacenter Panel'"
            :username="username"
            :profilePicLink="profilePicUrl"
            class="px-3 pb-3 mt-sm-1 mt-0 mb-3 mb-sm-1"
          />
          <div class="d-flex w-100 justify-content-end mx-3">
            <select
              v-model="filter"
              @change="filterPlans"
              class="dropdown no-border dark-text px-3 my-4"
            >
              <option value="open">Filter Open</option>
              <option value="complete">Filter Complete</option>
              <option value="expired">Filter Expired</option>
            </select>
          </div>
          <div class="row w-100">
            <div class="row w-100 pb-2" id="plans-header">
              <div class="col-4 pl-0">Order</div>
              <div class="col-2">Subnet</div>
              <div class="col-2 negative-margin">Valid until</div>
              <div class="col-2">User</div>
              <div class="col-2 pl-0">Status</div>
            </div>
          </div>

          <div class="accordion pt-4 px-3 w-100" id="accordionExample">
            <div
              v-for="plan in plans"
              :key="plan.id"
              class="card mb-3 rounded-corner dark-background"
            >
              <div class="card-header px-0 pt-1" :id="'heading' + plan.id">
                <button
                  @click="loadProxies(plan.id)"
                  class="btn p-0 btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  :data-target="'#' + 'collapse' + plan.id"
                  aria-expanded="false"
                  :aria-controls="'collapse' + plan.id"
                >
                  <div class="row ml-4 mr-4 pt-3 pb-2 heading-bottom-border">
                    <div class="col-4 pl-0">
                      {{ plan.name }}
                    </div>
                    <div class="col-2 pl-3">
                      {{ plan.subnet || "-" }}
                    </div>
                    <div class="col-2 pl-4">
                      {{ plan.expiration_time || "-" }}
                    </div>
                    <div class="col-2 pl-4">
                      {{ plan.discord_id }}
                    </div>
                    <div class="col-2">
                      {{ plan.fulfilled }}
                    </div>
                  </div>
                </button>
              </div>

              <div
                :id="'collapse' + plan.id"
                class="collapse"
                :aria-labelledby="'heading' + plan.id"
                data-parent="#accordionExample"
              >
                <div class="card-body tiny-title pt-0">
                  <div>
                    <form v-on:submit.prevent class="row">
                      <div class="col-3">
                        <label :for="'subnet' + plan.id" class="tiny-title"
                          >Input Subnet</label
                        >
                        <input
                          type="text"
                          class="no-border w-100 fancy-input"
                          :id="'subnet' + plan.id"
                          placeholder="Input subnet"
                        />
                        <button
                          v-on:click="finishOrder"
                          class="purchase-button no-border w-100 mt-3"
                          :id="plan.id + ',' + plan.type"
                        >
                          Submit Order
                        </button>
                      </div>
                      <div class="col-9">
                        <textarea
                          class="w-100 h-100 no-border rounded-corner p-3 fancy-text-area"
                          :id="'proxy-area' + plan.id"
                        >
                        </textarea>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer class="mr-1 mb-0 w-100 mt-5 pl-4 pr-2" id="footer" />
      </div>
    </div>
    <Loading v-if="loaded == false" />
  </div>
</template>

<script>
import Footer from "./Footer.vue";
import Heading from "./Heading.vue";
import Loading from "./Loading.vue";
import axios from "axios";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  name: "DatacenterAdminPanel",
  components: {
    Footer,
    Heading,
    Loading,
  },
  data() {
    return {
      loaded: false,
      username: null,
      profilePicUrl: null,
      plans: [],
      filter: "open",
    };
  },
  methods: {
    async loadProxies(planId) {
      for (let i = 0; i < this.plans.length; i++) {
        if (
          this.plans[i].fulfilled == "Complete" &&
          this.plans[i].id == planId
        ) {
          document.getElementById(`proxy-area${planId}`).value =
            "Loading proxies";
          let proxyType = this.plans[i].type;

          if (proxyType == "zalando_dc") {
            proxyType = "zalando";
          }

          await axios
            .get(`${this.$apiUrl}/datacenter/${planId}/proxies`, {
              params: {
                sessionId: this.$parent.$parent.session.session_id,
                proxyType: proxyType,
              },
            })
            .then((result) => {
              document.getElementById(
                `proxy-area${planId}`
              ).value = result.data.join("\n");
            })
            .catch((error) => {
              document.getElementById(`proxy-area${planId}`).value =
                error.response.data.error;
            });
        }
      }
    },
    filterPlans() {
      switch (this.filter) {
        case "open":
          this.plans.sort((a, b) => {
            if (a.fulfilled == "Open" && b.fulfilled != "Open") {
              return -1;
            } else if (a.fulfilled != "Open" && b.fulfilled == "Open") {
              return 1;
            } else {
              return 0;
            }
          });
          break;
        case "complete":
          this.plans.sort((a, b) => {
            if (a.fulfilled == "Complete" && b.fulfilled != "Complete") {
              return -1;
            } else if (a.fulfilled != "Complete" && b.fulfilled == "Complete") {
              return 1;
            } else {
              return 0;
            }
          });
          break;
        case "expired":
          this.plans.sort((a, b) => {
            if (a.fulfilled == "Expired" && b.fulfilled != "Expired") {
              return -1;
            } else if (a.fulfilled != "Expired" && b.fulfilled == "Expired") {
              return 1;
            } else {
              return 0;
            }
          });
      }
    },
    async finishOrder(event) {
      event.target.disabled = true;
      event.target.innerText = "Submitting";
      const buttonData = event.target.id.split(",");

      if (buttonData[1] == "zalando_dc") {
        buttonData[1] = "zalando";
      }

      await axios.delete(
        `${this.$apiUrl}/datacenter/${buttonData[0]}/proxies`,
        {
          params: {
            sessionId: this.$parent.$parent.session.session_id,
            proxyType: buttonData[1],
          },
        }
      );

      const proxies = document
        .getElementById(`proxy-area${buttonData[0]}`)
        .value.split("\n");

      await axios
        .post(`${this.$apiUrl}/datacenter/${buttonData[0]}/proxies`, {
          sessionId: this.$parent.$parent.session.session_id,
          subnet: document.getElementById(`subnet${buttonData[0]}`).value,
          proxyType: buttonData[1],
          proxies: proxies,
        })
        .then(async () => {
          event.target.innerText = "Submitted Successfully";
          document.getElementById(`proxy-area${buttonData[0]}`).value =
            "Added successfully";
          await sleep(1500);
          for (let i = 0; i < this.plans.length; i++) {
            if (this.plans[i].id == buttonData[0]) {
              this.plans[i].fulfilled = "Complete";
              this.filterPlans();
            }
          }
          event.target.disabled = false;
          event.target.innerText = "Submit Order";
          document.getElementById(
            `proxy-area${buttonData[0]}`
          ).value = proxies.join("\n");
        })
        .catch(async (error) => {
          event.target.disabled = false;
          event.target.innerText = "Error When Submitting";
          document.getElementById(`proxy-area${buttonData[0]}`).value =
            error.response.data.error;
          await sleep(1500);
          event.target.innerText = "Submit Order";
        });
    },
  },
  mounted: async function () {
    // check session
    await this.$parent.$parent.checkSession();
    const session = this.$parent.$parent.session;

    if (!session.admin) {
      this.$router.push("dashboard");
    } else if (!session.discord_id) {
      this.$router.push("login");
    }

    this.username = `${session.display_name}#${session.tag}`;
    this.profilePicUrl = session.profile_picture_url;

    await axios
      .get(`${this.$apiUrl}/datacenter/plans`, {
        params: {
          sessionId: this.$parent.$parent.session.session_id,
        },
      })
      .then((result) => {
        const now = new Date().getTime();
        for (let i = 0; i < result.data.length; i++) {
          switch (result.data[i].type) {
            case "dc":
              result.data[i].name = `${result.data[i].quantity} Monthly DCs`;
              break;
            case "isp":
              result.data[i].name = `${result.data[i].quantity} Monthly ISPs`;
              break;
            case "zalando_dc":
              result.data[
                i
              ].name = `${result.data[i].quantity} Monthly Zalando DCs`;
              break;
            case "daily_dc":
              result.data[i].name = `${result.data[i].quantity} Daily DCs`;
              break;
          }
          if (result.data[i].expiration_time) {
            const date = new Date(parseInt(result.data[i].expiration_time));
            result.data[i].expiration_time = `${date
              .toLocaleString("default", { month: "long" })
              .slice(0, 3)} ${date.getDate()}, ${date.getFullYear()}`;

            if (now > date.getTime()) {
              result.data[i].fulfilled = "Expired";
              continue;
            }
          }

          if (result.data[i].fulfilled) {
            result.data[i].fulfilled = "Complete";
          } else {
            result.data[i].fulfilled = "Open";
          }
        }
        this.plans = result.data;
      });

    this.loaded = true;
  },
};
</script>

<style scoped>
#manage-dc-container {
  min-height: 87vh;
}

.dropdown {
  background-color: #202634;
  padding: 10px;
  color: #c2d7e8;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical {
  background: rgb(71, 71, 71);
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 30px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgb(48, 48, 48);
  -webkit-border-radius: 100px;
}

.fancy-text-area {
  background-color: #11151e;
  resize: none;

  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  color: #c2d7e8;
}

.purchase-button {
  background-color: #bde3f4;
  color: #191e29 !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: clamp(5px, 7%, 50px);
  padding-right: clamp(5px, 7%, 50px);
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 5px;
}

.purchase-button:hover {
  background-color: #aad1e3;
  box-shadow: 5px 0px 10px 5px #00000054 !important;
}

.fancy-input {
  background-color: #202634;
  font-size: 0.9375rem;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  color: #bde3f4;
}

.fancy-input:focus {
  color: #bde3f4;
}

.negative-margin {
  margin-right: -4px;
}

.negative-margin-2 {
  margin-left: -12px;
}

#plans-header {
  border-bottom-style: solid;
  border-bottom-width: 0.2px;
  border-bottom-color: #bde3f426;
  margin-right: 0.25rem;
  margin-left: 2.85rem;
}

h1 {
  font-size: 2.8125rem !important;
  font-weight: 900 !important;
}

#accordionExample {
  padding-left: calc(0.5rem + 5%);
  padding-right: 7%;
}

button {
  color: #bde3f4 !important;
}
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

button[aria-expanded="true"] .heading-bottom-border {
  border-bottom-color: #bde3f426 !important;
  border-bottom-width: 0.02px !important;
  border-bottom-style: solid !important;
  padding-bottom: 1.25rem !important;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

button[aria-expanded="false"] .heading-bottom-border {
  border-bottom-style: none !important;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.dark-background {
  background-color: #191e29 !important;
}
</style>