<template>
<div class="col">
    <div :hidden="loaded == false">
        <div class="d-flex justify-content-between flex-wrap h-100 pr-lg-3 m-0">
            <div class="pt-lg-2">
                <Heading :customStyles="'margin-right: -15px;'" :pageTitle="'My Account'" :username="username" :profilePicLink="profilePicUrl" class="mb-3 mt-sm-1 mt-0"/>
                <div class="justify-content-xl-between row d-flex flex-wrap pt-3 pt-sm-1" id="content-container">
                    <div class="col-12 col-lg-3 mb-lg-0 mb-4" id="profile-container">
                        <div class="jumbotron rounded-corner product-box h-95 pb-4">
                            <div class="pb-2">
                                <h3 class="tiny-title mb-4 mt-2">Profile</h3>
                                <div id='profile-container-big' class="tiny-title dark-text w-100">
                                    <div class="d-flex align-items-center w-100 justify-content-center">
                                        <img id="profile-pic" class="mb-4" :src="profilePicUrl">
                                    </div>
                                    <span class="subtitle-muted">Welcome back {{ displayName }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 pl-lg-2 pl-3 mb-lg-0 mb-4" id="orders">
                        <div class="jumbotron rounded-corner product-box h-95 pb-0">
                            <h3 class="tiny-title">Orders</h3>
                            <div class="box-background rounded-corner w-100 mt-4">
                                <div class="row pb-2 mx-0" id="top-row">
                                    <div class="col-6 px-0 cell semi-bold">
                                        Plan
                                    </div>
                                    <div class="col-3 px-0 cell semi-bold">
                                        Amount
                                    </div>
                                    <div class="col-3 px-0 cell semi-bold">
                                        User
                                    </div>
                                </div>

                                <div class="row mt-3 mb-3 pb-1 mx-0" v-for="purchase in purchases" :key="purchase.timestamp">
                                    <div class="col-6 px-0 cell">
                                        {{ purchase.name }}
                                    </div>
                                    <div class="col-3 px-0 cell">
                                        {{ purchase.quantity }}
                                    </div>
                                    <div class="col-3 px-0 cell">
                                        {{ username }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-2-5 pl-lg-2 pl-3 pr-sm-3 pr-2 pb-0 mb-lg-0 mb-4">
                        <div class="jumbotron rounded-corner product-box h-95 pb-0 progress-box px-3 d-flex flex-column align-items-center">
                            <h3 class="tiny-title">Your DC/ISP</h3>
                            <div class="mt-4 progress-bar-container" id="dc-count">
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-2-5 pl-lg-2 pl-3 mb-lg-0 mb-4">
                        <div class="jumbotron rounded-corner product-box h-95 pb-0 progress-box px-3 d-flex flex-column align-items-center">
                            <h3 class="tiny-title">Your Residential</h3>
                            <div class="mt-4 progress-bar-container" id="resi-count"></div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-7 mb-lg-0 mb-4" id="notification-box">
                        <div class="jumbotron rounded-corner product-box h-95 mb-0 pb-4 pb-lg-0">
                            <h3 class="tiny-title mb-3 mt-2">Notifications</h3>
                            <form v-on:submit.prevent>
                                <div class="form-group">
                                    <label for="webhook" class="small dark-text">Discord Webhook</label> <br>
                                    <input v-model="webhook" type="text" class="no-border" id="webhook" placeholder="Input webhook...">
                                </div>
                                <button type="submit" v-on:click="saveWebhook" class="purchase-button no-border mr-3 mt-1">Save</button>
                                <button id="webhook-button" v-on:click="testWebhook" class="purchase-button no-border mt-2">Test Webhook</button>
                            </form>
                        </div>
                    </div>
                    <div class="col-12 col-lg-5 pl-lg-2 pl-3 mb-sm-0 mb-4" id="account-box">
                        <div class="jumbotron rounded-corner product-box h-95 pb-0">
                            <div class="pt-2">
                                <h3 class="tiny-title mb-4">Account Analytics</h3>
                                <h4 class="subtitle-muted tiny-title">Total purchased data</h4>
                                <div class="bold fixed-medium-text d-flex flex-row"><div id="total-resi" class="pr-1"></div> GB</div>
                                <h4 class="subtitle-muted tiny-title">Total purchased DC/ISP</h4>
                                <!--<p class="bold fixed-medium-text" id="total-dc">{{ totalDc }}</p>-->
                                <p class="bold fixed-medium-text" id="total-dc"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer class="mr-1 mb-0 w-100 mt-4 pt-4 pl-4 pr-2" id="footer"/>
        </div>
    </div>
    <Loading v-if="loaded == false"/>
</div>
</template>

<script>
import ProgressBar from 'progressbar.js'
import Footer from './Footer.vue'
import Heading from './Heading.vue'
import Loading from './Loading'
import axios from 'axios'
import { CountUp } from 'countup.js';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  name: 'UserProfile',
  components: {
      Footer,
      Heading,
      Loading
  },
  data() {
      return {
          loaded: false,
          webhook: '',
          dcCount: 0,
          resiCount: 0,
          usedResi: 0,
          totalDc: 0,
          totalResi: 0,
          purchases: [],
          username: null,
          profilePicUrl: null,
          displayName: null
      }
  },
  methods: {
      analytics() {
          const dcCount = new CountUp('total-dc', this.totalDc, { duration: 2 })
          const resiCount = new CountUp('total-resi', this.resiCount, { duration: 2 })

          dcCount.start()
          resiCount.start()
      },
      progressBars() {
        document.getElementById('dc-count').innerHTML = ''
        var dcCount = this.dcCount
        var dcBar = new ProgressBar.Circle('#dc-count', {
                color: '#BDE3F4',
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: 10,
                easing: 'easeInOut',
                duration: 1400,
                trailWidth: 10,
                trailColor: '#202634',
                text: {
                    autoStyleContainer: false,
                },
                from: { color: '#BDE3F4', width: 10 },
                to: { color: '#BDE3F4', width: 10 },
                // Set default step function for all animate calls
                step: function(state, circle) {
                    circle.path.setAttribute('stroke', state.color);
                    circle.path.setAttribute('stroke-width', state.width);
                    circle.setText(Math.round(dcCount * circle.value()));
                }
        });
        dcBar.text.style.top = '50%'
        dcBar.text.style.fontSize = '1.925rem'
        dcBar.text.style.textAlign = 'center'
        dcBar.text.style.whiteSpace = 'nowrap'
        dcBar.text.className += ' bold'
        dcBar.animate(1.0);  

        var usedResi = Number(this.usedResi).toFixed(2)
        var totalResi = Number(this.resiCount).toFixed(2)
        var unusedResi = Number(this.resiCount - this.usedResi).toFixed(2)
        var now = new Date().getTime();

        document.getElementById('resi-count').innerHTML = ''
        var resiBar = new ProgressBar.Circle('#resi-count', {
            color: '#BDE3F4',
            trailWidth: 10,
            trailColor: '#202634',
            easing: 'easeInOut',
            duration: 1400,
            text: {
                autoStyleContainer: false
            },
            from: { color: '#BDE3F4', width: 10 },
            to: { color: '#BDE3F4', width: 10 },
            // Set default step function for all animate calls
            step: function(state, circle) {
                circle.path.setAttribute('stroke', state.color);
                circle.path.setAttribute('stroke-width', state.width);
                let animationPercent = ((new Date().getTime()) - now) / 1400
                if (animationPercent > 1) {
                    animationPercent = 1
                } else if (animationPercent >= 0.90) {
                    animationPercent = 1
                }
                circle.setText(`<p class="bold canva-text mt-2 pt-1" style="margin: 0;">${parseFloat(unusedResi * animationPercent).toFixed(2)} GB</p><p class="progress-bar-text-secondary" style="margin: 0; font-size: 0.688rem !important;">${parseFloat(usedResi * animationPercent).toFixed(2)} GB used</p>`)
            }
        });
        
        resiBar.text.style.top = '50%'
        resiBar.text.style.textAlign = 'center'
        resiBar.text.style.whiteSpace = 'nowrap'
        if (totalResi == 0) {
            resiBar.animate(0); 
        } else {
            resiBar.animate(1 - (usedResi / totalResi))
        }
    },
    async testWebhook(event) {
        event.target.disabled = true
        event.target.innerText = "Testing..."

        await axios.post(this.webhook, {
            content: 'Your Infinity Proxies Webhook is working!'
        })
            .then(async() => {
                event.target.disabled = false
                event.target.innerText = "Sent test message"
                await sleep(1500)
                event.target.innerText = "Test Webhook"
            })
            .catch(async() => {
                event.target.disabled = false
                event.target.innerText = "Invalid webhook"
                await sleep(1500)
                event.target.innerText = "Test Webhook"
            })
    },
    async saveWebhook(event) {
        event.target.disabled = true
        event.target.innerText = "Saving..."

        await axios.post(`${this.$apiUrl}/users/${this.$parent.$parent.session.discord_id}/webhook`, {
            sessionId: this.$parent.$parent.session.session_id,
            webhook: this.webhook
        })
            .then(async() => {
                event.target.disabled = false
                event.target.innerText = "Saved"
                await sleep(1500)
                event.target.innerText = "Save"
            })
    }
  },
    mounted: async function() {
        // check session
        await this.$parent.$parent.checkSession()
        const session = this.$parent.$parent.session
        this.username = `${session.display_name}#${session.tag}`
        this.displayName = session.display_name
        this.profilePicUrl = session.profile_picture_url

        if (!session.discord_id) {
            this.$router.push('login')
        }

        Promise.all([
            axios.get(`${this.$apiUrl}/users/${session.discord_id}/webhook`, {
                params: {
                    sessionId: session.session_id
                }
            })
                .then(result => {
                    this.webhook = result.data.discord_webhook
                }),
            axios.get(`${this.$apiUrl}/users/${session.discord_id}/purchases`, {
                params: {
                    sessionId: session.session_id
                }
            })
                .then(result => {
                    const data = result.data.reverse()
                    for (let i = 0; i < data.length && i < 8; i++) {
                        let purchase = {}
                        purchase.quantity = parseInt(data[i].sku.replace(/[A-Z]+/, ''))
                        purchase.timestamp = data[i].timestamp

                        switch (result.data[i].sku.replace(/\d+/, '')) {
                            case 'ZAL':
                                purchase.name = 'Zalando Proxies'
                                this.totalDc += purchase.quantity
                                break;
                            case 'ISP': 
                                purchase.name = 'ISP Proxies'
                                this.totalDc += purchase.quantity
                                break;
                            case 'DC':
                                purchase.name = 'Datacenter Proxies'
                                this.totalDc += purchase.quantity
                                break;
                            case 'MESH':
                                purchase.name = 'Residential Mesh'
                                this.totalResi += purchase.quantity
                                break;
                            case 'BUGT':
                                purchase.name = 'Residential Budget'
                                this.totalResi += purchase.quantity
                                break;
                            case 'PREM':
                                purchase.name = 'Residential Premium'
                                this.totalResi += purchase.quantity
                                break
                        }

                        this.purchases.push(purchase)
                    }
                }),
            axios.get(`${this.$apiUrl}/residential/${session.discord_id}`, {
                params: {
                    sessionId: session.session_id
                }
            })
                .then(result => {
                    if (result.data.mesh) {
                        this.resiCount += parseFloat(result.data.mesh.totalData)
                        this.usedResi += (parseFloat(result.data.mesh.totalData) - parseFloat(result.data.mesh.remainingData))
                    }
                    if (result.data.budget) {
                        this.resiCount += parseFloat(result.data.budget.totalData)
                        this.usedResi += (parseFloat(result.data.budget.totalData) - parseFloat(result.data.budget.remainingData))
                    }
                    if (result.data.premium) {
                        this.resiCount += parseFloat(result.data.premium.totalData)
                        this.usedResi += (parseFloat(result.data.premium.totalData) - parseFloat(result.data.premium.remainingData || 0))
                    }

                }),
            axios.get(`${this.$apiUrl}/datacenter/${session.discord_id}/count`, {
                params: {
                    sessionId: session.session_id
                }
            })
                .then(result => {
                    this.dcCount = result.data.count
                })
        ])
            .then(() => {
                this.loaded = true
                this.progressBars()
                this.analytics()
            })
  }
}
</script>

<style scoped>
.purchase-button:hover {
    background-color: #aad1e3;
    box-shadow: 5px 0px 10px 5px #00000054 !important;
}

h2 {
    font-size: 2.375rem;
}

.progress-bar-container {
    position: relative;
}

#profile-container {
    flex: 0 0 23%;
    max-width: 23%;
}

#profile-container-big span {
    font-size: 0.813rem;
}

#notification-box {
    flex: 0 0 56.333333%;
    max-width: 56.333333%;
}

#account-box {
    flex: 0 0 43.666667%;
    max-width: 43.666667%;
}

#webhook-button {
    width: auto;
}

#webhook {
    background-color: #202634;
    font-size: 0.9375rem;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
    width: 85%;
    color: #BDE3F4;
}

#webhook:focus {
    color: #BDE3F4;
}

#content-container {
    /* margin-right: 0 !important; */
    padding-right: 9.5rem !important;
}

@media (max-width: 1400px) {
    #content-container {
        /* margin-right: 0 !important; */
        padding-right: 0 !important;
    }
}


.dark-text {
    color: #6D7B88
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.dropdown {
    background-color: #202634;
    padding: 10px;
}

.h-95 {
    height: 96%;
}

.no-border {
    border:0px;
    outline:0px;
}

#account-heading {
    padding-left: 15px;
}

.col-2-5 {
    flex: 0 0 21.83%;
    max-width: 21.83%;
}

@media (max-width: 576px) {
    .progress-bar-container >>> svg {
        height: 122px !important;
    }
}

@media (max-width: 1200px) {
    .col-2-5 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    #profile-container,
    #notification-box,
    #account-box {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width: 991px) {
    .col-2-5 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    #profile-container,
    #notification-box,
    #account-box {
        flex: 0 0 100%;
        max-width: 100%;
    }

    #profile-pic {
        max-height: 200px;
        max-width: 200px;
    }

    .progress-box {
        font-size: 0.5rem;
    }

    .h-95 {
        height: 100%;
    }

    #webhook-button {
        width: 100%;
    }

    #webhook {
        width: 100%;
    }
}

.nav-item:hover i::before {
    color: #50FFF9;
}

.active-item {
    color: #BDE3F4 !important;
}

.nav-item:hover > a {
    color: #BDE3F4
}

.nav-item > a {
    color: #191E29;
}

.rounded-corner {
    border-radius: 5px;
}

.product-box {
    background-color: #191E29;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.purchase-button {
    background-color: #BDE3F4;
    color: #191E29;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: clamp(5px, 7%, 50px);
    padding-right: clamp(5px, 7%, 50px);
    font-size: 0.9375rem;
    font-weight: bold;
    border-radius: 5px;
}

#footer > a {
    margin-left: 2.5%;
    margin-right: 2.5%;
}

#menu-bar {
    background-color: #0D0A17;
}

#profile-pic {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    max-width: 235px;
    max-height: 235px;
}

.cell {
    font-size: 0.75rem;
    color: #C2D7E8CC;
    word-wrap: break-word;
}

@media (min-width: 768px) {
    #menu-bar {
        max-width: 350px;
    }
}

@media (max-width: 767px) {
    #header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    #footer {
        margin-top: 8rem;
    }
}
</style>
