<template>
    <div class="row" id="features">
        <div class="col-12 col-lg-6 feature-margin reveal">
            <div class="jumbotron product-box h-100 jumbo-padding rounded-corner">
                <h3 class="small-title pb-2">Simple Dashboard</h3>
                <p class="body-text">Our Proxies can be generated via our userfriendly Dashboard. Fast switching of your proxy lists, saves you time, while securing limited items. Up-to-date usage helps you, to scale your usage on the point.</p>
            </div>
        </div>
        <div class="col-12 col-lg-6 feature-margin reveal">
            <div class="jumbotron product-box h-100 jumbo-padding rounded-corner">
                <h3 class="small-title pb-2">Endless Unique IPs</h3>
                <p class="body-text">Our residential plans, give you access to millions of unique proxys. Generate your proxies, in every country where you want, to get the best results for your needs.</p>
            </div>
        </div>
        <div class="col-12 col-lg-6 feature-margin reveal">
            <div class="jumbotron product-box h-100 jumbo-padding rounded-corner">
                <h3 class="small-title pb-2">High Speed Performance</h3>
                <p class="body-text">Excellent speeds are necessary, when it comes to secure limited items on the market. All of our proxys guarantee the best performance and speed, because they are optimized for limited releases.</p>
            </div>
        </div>
        <div class="col-12 col-lg-6 feature-margin reveal">
            <div class="jumbotron product-box h-100 jumbo-padding rounded-corner">
                <h3 class="small-title pb-2">More Plans, More Choices</h3>
                <p class="body-text">Our specially developed different proxyplans, for specific sites, automatically gives you an advandage over others. Shopping on a wide range of sites, will not be a problem anymore.</p>
            </div>
        </div>
        <div class="col-12 col-lg-6 feature-margin reveal">
            <div class="jumbotron product-box h-100 jumbo-padding rounded-corner">
                <h3 class="small-title pb-2">Rotating and Sticky</h3>
                <p class="body-text">No matter which type of proxys you need, we got you covered. Create thousands of either rotating or sticky proxys via the dasboard, the choice is all yours.</p>
            </div>
        </div>
        <div class="col-12 col-lg-6 feature-margin reveal">
            <div class="jumbotron product-box h-100 jumbo-padding rounded-corner">
                <h3 class="small-title pb-2">Datacenter Proxys</h3>
                <p class="body-text">Our datacenter proxys are trimmed to perfection and you can manage them in our dashboard. Decide betweeen different dc plans for specific sites to get the best speeds for your needs.</p>
            </div>
        </div>
        <div class="col-12 d-sm-block d-none reveal"> 
            <p class="feature-sub-text muted-text">All our plans expire after 30 days unless stated otherwise. We reserve the right to terminate your access at any time if we believe you are not using the service responsibly. Please refer to the refund policy and terms of service for further details.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>

<style scoped>
h3 {
    font-weight: 900;
}

.feature-sub-text {
    font-size: 0.938rem;
}

.feature-margin {
    margin-bottom: 1.6875rem;
}

.jumbo-padding {
    padding-top: 1.375rem;
    padding-bottom: 3.8rem;
    padding-left: 2rem;
}

.body-text {
    font-size: 0.9375rem;
}

p {
    width: 90%;
    margin-bottom: 0;
}

.product-box {
    background-color: #191E29;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media (max-width: 767px) {
    #features {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

@media (max-width: 620px) {
    .feature-margin {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-bottom: 1.375rem;
    }

    .jumbo-padding {
        padding-left: 1.5rem;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
    }
}
</style>