<template>
<div id="terms-text-container">
<h1 class="my-5">Terms of Service</h1>
Mit dem Kauf eines Infinity Supply-Produkts erklären Sie sich damit einverstanden, unser Proxy-Netzwerk nur für legitime Zwecke zu nutzen. Wenn wir glauben, dass Sie den Service missbrauchen oder unverantwortlich nutzen, haben wir das Recht, Ihre Bestellung mit sofortiger Wirkung zu kündigen. Alle Pläne haben eine Laufzeit von 30 Tagen, bevor sie ablaufen. Bitte stellen Sie also sicher, dass Sie Ihre Daten (Wohnsitzbestellungen) vor dem Ablaufdatum nutzen. Wie in unseren Rückerstattungsrichtlinien angegeben, bieten wir keine Rückerstattungen oder Ersatz an, also stellen Sie bitte sicher, dass Sie Ihre Proxys ordnungsgemäß verwenden. Mit dem Kauf erklären Sie sich mit diesen Bedingungen einverstanden.

<h4 class="bold my-4">1. Bedingungen</h4>
Mit dem Zugriff auf die Website https://www.infinity-supply.eu/ erklären Sie sich mit diesen Nutzungsbedingungen und allen anwendbaren Gesetzen und Vorschriften einverstanden und stimmen zu, dass Sie für die Einhaltung aller anwendbaren lokalen Gesetze verantwortlich sind. Wenn Sie mit einer dieser Bedingungen nicht einverstanden sind, ist es Ihnen untersagt, diese Website zu nutzen oder darauf zuzugreifen.
<br><br>
Die auf dieser Website enthaltenen Materialien sind durch geltendes Urheber- und Markenrecht geschützt.

<h4 class="bold my-4">2. Bedingungen für die Nutzung</h4>
Mit dem Kauf von digitalen Produkten von INFINITY SUPPLY erklären Sie sich damit einverstanden, die zur Verfügung gestellten Proxys nur für legitime und legale Zwecke zu verwenden. Der Zweck der verkauften Proxys ist die Nutzung bei einer Reihe von Sneaker-Store-Händlern. Wir behalten uns das Recht vor, Ihren Zugang zu diesem Service zu entfernen, wenn wir Grund zu der Annahme haben, dass Sie Ihren Kauf für böswillige Zwecke nutzen.

<h4 class="bold my-4">3. Haftungsausschluss</h4>
Die Materialien auf der INFINITY SUPPLY-Website werden auf einer "wie besehen"-Basis bereitgestellt. INFINITY SUPPLY gibt keine ausdrücklichen oder stillschweigenden Garantien und lehnt hiermit alle anderen Garantien ab, einschließlich, aber nicht beschränkt auf stillschweigende Garantien oder Bedingungen der Marktgängigkeit, der Eignung für einen bestimmten Zweck oder der Nichtverletzung von geistigem Eigentum oder anderen Rechtsverletzungen. Darüber hinaus übernimmt INFINITY SUPPLY keine Gewähr für die Richtigkeit, die wahrscheinlichen Ergebnisse oder die Zuverlässigkeit der Verwendung der Materialien auf seiner Website oder in anderer Weise in Bezug auf diese Materialien oder auf Websites, die mit dieser Website verknüpft sind, und gibt auch keine Zusicherungen ab.

<h4 class="bold my-4">4. Beschränkungen</h4>
In keinem Fall haften INFINITY SUPPLY oder seine Zulieferer für Schäden (einschließlich, aber nicht beschränkt auf Schäden durch Daten- oder Gewinnverluste oder Geschäftsunterbrechungen), die sich aus der Nutzung oder der Unmöglichkeit der Nutzung der Materialien auf der INFINITY SUPPLY-Website ergeben, selbst wenn ein autorisierter Vertreter von INFINITY SUPPLY mündlich oder schriftlich über die Möglichkeit solcher Schäden informiert wurde. Da einige Gerichtsbarkeiten keine Beschränkungen von stillschweigenden Garantien oder Haftungsbeschränkungen für Folgeschäden oder zufällige Schäden zulassen, gelten diese Beschränkungen möglicherweise nicht für Sie.

<h4 class="bold my-4">5. Genauigkeit der Materialien</h4>
Die Materialien auf der INFINITY SUPPLY-Website können technische, typografische oder fotografische Fehler enthalten. INFINITY SUPPLY übernimmt keine Garantie für die Richtigkeit, Vollständigkeit oder Aktualität der Materialien auf seiner Website. INFINITY SUPPLY behält sich das Recht vor, jederzeit und ohne Vorankündigung Änderungen an den auf der Website enthaltenen Materialien vorzunehmen. INFINITY SUPPLY übernimmt jedoch keine Verpflichtung zur Aktualisierung der Materialien.

<h4 class="bold my-4">6. Links</h4>
INFINITY SUPPLY hat nicht alle mit seiner Website verlinkten Websites überprüft und ist nicht für den Inhalt dieser verlinkten Websites verantwortlich. Die Aufnahme eines Links bedeutet nicht, dass INFINITY SUPPLY die Website befürwortet. Die Nutzung einer solchen verlinkten Website erfolgt auf eigenes Risiko des Nutzers.

<h4 class="bold my-4">7. Änderungen</h4>
INFINITY SUPPLY kann diese Nutzungsbedingungen für seine Website jederzeit und ohne Vorankündigung überarbeiten. Durch die Nutzung dieser Website (und jeder anderen Website, die sich im Besitz von INFINITY SUPPLY befindet und von ihr betrieben wird) erklären Sie sich damit einverstanden, an die jeweils aktuelle Version dieser Nutzungsbedingungen gebunden zu sein.

<h4 class="bold my-4">8. Geltendes Recht</h4>
Die Nutzungsbedingungen unterliegen dem deutschen Recht und Sie unterwerfen sich unwiderruflich der ausschließlichen Zuständigkeit der Gerichte in diesem Staat oder an diesem Ort.
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
h1 {
    font-size: 2.8125rem !important;
    font-weight: 900 !important;
}

#terms-text-container {
    font-size: 1.25rem;
    padding-left: calc(0.5rem + 5%);
    padding-right: 7%;
}
</style>