<template>
<div class="row min-vh-100">
    <DashNav :current-nav="'account'"/>
    <UserProfile class="mx-4 mt-4 mb-4 pl-md-4 pr-md-4 pt-1" id="user-profile"/>
</div>
</template>

<script>
import DashNav from '../components/dashboard/DashNav.vue'
import UserProfile from '../components/dashboard/UserProfile.vue'

export default {
  name: 'Account',
  components: {
    DashNav,
    UserProfile
  }
}
</script>

<style scoped>
@media (max-width: 767px) {
  #user-profile {
    margin-right: 1.5rem !important;
  }
}
</style>
