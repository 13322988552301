<template>
<div class="min-vh-100" id="home">
    <div id="home-nav-component">
        <HomeNav/>
    </div>
    <div class="sky">
        <div class="shooting-stars"></div>
    </div>
    <div id="slogan-component" class="margin-x-15 reveal">
        <Slogan id="slogan-component"/>
    </div>

    <div id="catch-line-component" class="margin-x-15 margin-t-15 reveal">
        <CatchLine/>
    </div>
    
    <div id="features-background" class="w-100 reveal">
        <div id='features-component' class="margin-x-15">
            <Features/>
        </div>
    </div>

    <div id="datacenter-speeds-component" class="margin-x-15 margin-t-15 px-sm-0 px-1 reveal">
        <DatacenterSpeeds/>
    </div>

    <div id="contact-component" class="margin-x-15 margin-t-20 reveal">
        <Contact/>
    </div>

    <div id="footer-component reveal">
        <Footer/>
    </div>
</div>
</template>

<script>
import HomeNav from '../components/home/HomeNav.vue'
import Slogan from '../components/home/Slogan.vue'
import Features from '../components/home/Features.vue'
import CatchLine from '../components/home/CatchLine.vue'
import DatacenterSpeeds from '../components/home/DatacenterSpeeds.vue'
import Contact from '../components/home/Contact.vue'
import Footer from '../components/home/Footer.vue'
import ScrollReveal from 'scrollreveal'

export default {
    name: 'Home',
    components: {
       HomeNav,
        Slogan,
        Features,
        CatchLine,
        DatacenterSpeeds,
        Contact,
        Footer
    },
    mounted() {
        ScrollReveal().reveal('.reveal')
    }
}
</script>

<style>
@import '../assets/css/stars.css';

#home {
    padding-left: 1%;
    margin-left: 1%;
}

.margin-x-15 {
    margin-left: 15%;
    margin-right: 15%;
}

#slogan-component {
    margin-right: 8% !important;
    margin-top: 12%;
}

#features-component {
    margin-top: 100px;
}

@media (max-width: 620px) {
    #slogan-component {
        margin-right: 5%;
    }

    .margin-x-15 {
        margin-left: 5%; 
        margin-right: 5%;
    }

    .margin-t-15 {
        margin-top: 60px !important;
    }

    .margin-t-20 {
        margin-top: 120px !important;
    }

    #home {
        padding-left: 0;
        margin-left: 0;
    }

    #features-component {
        margin-top: 10px;
    }
}

#catch-line-component {
    margin-top: 200px;
}

.margin-t-15 {
    margin-top: 170px;
}

.margin-t-20 {
    margin-top: 280px;
}

#footer-component {
    margin-top: 120px;
    margin-bottom: 2.25rem;
}

#features-background {
    background-image: url('../assets/svg/features-background.svg'); 
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-origin: content-box;
    background-attachment: scroll;
    background-color: #0F1218;
    background-blend-mode: color-dodge;
}
</style>
