<template>
<div class="row min-vh-100">
  <DashNav :current-nav="'datacenter'"/>
  <DatacenterTable class="ml-4 pl-4 mr-3 mt-4 pt-sm-3 pt-0 mb-4 pr-md-3 pr-0"/>
</div>
</template>

<script>
import DashNav from '../components/dashboard/DashNav.vue'
import DatacenterTable from '../components/dashboard/DatacenterTable.vue'

export default {
  name: 'Datacenter',
  components: {
    DashNav,
    DatacenterTable
  }
}
</script>

<style>
#app {
    background-color: #0F1218;
    font-family: 'Montserrat', sans-serif;
    color: #C2D7E8;
    overflow-x: hidden;
    scrollbar-width: none;
}

.min-vh-100 {
    min-height: 100vh;
}

body::-webkit-scrollbar {
    display: none;
}


</style>
