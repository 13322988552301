<template>
<div class="min-vh-100" id="login-component">
  <LoginMenu/>
  <div class="sky">
    <div class="shooting-stars"></div>
  </div>
</div>
</template>

<script>
import LoginMenu from '../components/LoginMenu.vue'

export default {
  name: 'Login',
  components: {
    LoginMenu
  },
}
</script>

<style scoped>
@import '../assets/css/stars.css';

#login-component {
    background-image: url('../assets/svg/features-background.svg'); 
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-origin: content-box;
    background-attachment: scroll;
    background-color: #10131A;
    background-blend-mode: color-dodge;
    overflow: hidden;
}
</style>
