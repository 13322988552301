<template>
<div class="row min-vh-100">
  <DashNav :current-nav="'dashboard'"/>
  <UserDashboard class="mx-4 mt-4 mb-4 pl-md-4 pr-md-4 pt-1"/>
</div>
</template>

<script>
import DashNav from '../components/dashboard/DashNav.vue'
import UserDashboard from '../components/dashboard/UserDashboard.vue'

export default {
  name: 'Dashboard',
  components: {
    DashNav,
    UserDashboard
  }
}
</script>
