<template>
<div class="col">
    <div v-if="loaded" class="h-100">
        <div v-if="pageStatus">
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog five-margin" role="document">
                    <div class="modal-content modal-container">
                        <div class="modal-header p-0 pb-2 m-3">
                            <h5 class="modal-title" id="exampleModalLabel">Input Discount Code</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="x-button">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body no-border pb-0">
                            <p>Input a discount code if you have one. If not, just click checkout.</p>
                        </div>
                        <div class="modal-footer no-border d-flex justify-content-between">
                            <input class="no-border pr-4" type="text" id="discount-box" v-model="discountCode" placeholder="Discount code here...">
                            <button type="button" class="btn modal-button px-5" v-on:click="checkout">Checkout</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="justify-content-between d-flex row h-100 w-100 m-0">
                <div class="w-100">
                    <Heading style="margin-left: -15px; width: calc(100% + 15px) !important;" :pageTitle="'Residential Plan Options'" :username="username" :profilePicLink="profilePicUrl" class="mb-2"/>
                    <div class="row mt-sm-5 mt-4 pt-1">
                        <div class="col-12 mb-3 col-lg-6 pl-0 mt-2">
                            <div class="jumbotron w-90  rounded-corner product-box mr-sm-2 mr-0">
                                <h3>Residential Mesh Pool</h3>
                                <p class="tiny-title my-3">Perfect for Mesh Sites. A private mesh Pool combined with powerful secret technics and amazing speeds.</p>
                                <div class="d-flex flex-row flex-wrap justify-content-between">
                                    <h6 class="dark-text">Select Amount</h6>
                                    <form v-on:submit.prevent class="w-100">
                                        <div class="d-flex justify-content-between w-100 flex-wrap">
                                            <select v-model="products.mesh" class="dropdown no-border dark-text" :disabled="residentialMeshStatus == false">
                                                <option value="DISABLED" :hidden="residentialMeshStatus">Temporarily Disabled</option>
                                                <option value="MESH1" :disabled="meshStock < 1">1 GB / 14 €<div v-if="meshStock < 1"> - OOS</div></option>
                                                <option value="MESH2" :disabled="meshStock < 2">2 GB / 28 €<div v-if="meshStock < 2"> - OOS</div></option>
                                                <option value="MESH5" :disabled="meshStock < 5">5 GB / 70 €<div v-if="meshStock < 5"> - OOS</div></option>
                                                <option value="MESH10" :disabled="meshStock < 10">10 GB / 130 €<div v-if="meshStock < 10"> - OOS</div></option>
                                                <option value="MESH20" :disabled="meshStock < 20">20 GB / 250 €<div v-if="meshStock < 20"> - OOS</div></option>
                                                <option value="MESH50" :disabled="meshStock < 50">50 GB / 600 €<div v-if="meshStock < 50"> - OOS</div></option>
                                                <option value="MESH100" :disabled="meshStock < 50">100 GB / 1100 €<div v-if="meshStock < 50"> - OOS</div></option>
                                            </select>
                                            <input :disabled="residentialMeshStatus == false || meshStock < 1" v-on:click="selectProduct" data-toggle="modal" data-target="#exampleModal" id="mesh" type="submit" class="purchase-button no-border bold" value="Purchase">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-3 col-lg-6 pl-0 mt-2">
                            <div class="jumbotron w-90  rounded-corner product-box ml-sm-2 ml-0">
                                <h3>Residential Budget Pool</h3>
                                <p class="tiny-title my-3">Perfect for all your favorite Sites and Raffles where Speed isn’t your first priority. Working on Footsites, Shopify, Off-White, SNKRS, Supreme and many more.</p>
                                <div class="d-flex flex-row flex-wrap justify-content-between">
                                    <h6 class="dark-text">Select Amount</h6>
                                    <div class="w-100"></div>
                                    <form v-on:submit.prevent class="w-100">
                                        <div class="d-flex justify-content-between w-100 flex-wrap">
                                            <select v-model="products.budget" class="dropdown no-border dark-text" :disabled="residentialStatus == false">
                                                <option value="DISABLED" :hidden="residentialStatus">Temporarily Disabled</option>
                                                <option value="BUGT1" :disabled="budgetStock < 1">1 GB / 10 €<div v-if="budgetStock < 1"> - OOS</div></option>
                                                <option value="BUGT2" :disabled="budgetStock < 2">2 GB / 18 €<div v-if="budgetStock < 2"> - OOS</div></option>
                                                <option value="BUGT5" :disabled="budgetStock < 5">5 GB / 35 €<div v-if="budgetStock < 5"> - OOS</div></option>
                                                <option value="BUGT10" :disabled="budgetStock < 10">10 GB / 65 €<div v-if="budgetStock < 10"> - OOS</div></option>
                                                <option value="BUGT20" :disabled="budgetStock < 20">20 GB / 120 €<div v-if="budgetStock < 20"> - OOS</div></option>
                                            </select>
                                            <input :disabled="residentialStatus == false || budgetStock < 1" v-on:click="selectProduct" data-toggle="modal" data-target="#exampleModal" id="budget" type="submit" class="purchase-button no-border bold" value="Purchase">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-3 col-lg-6 pl-0 mt-sm-0 mt-2">
                            <div class="jumbotron w-90  rounded-corner product-box mr-sm-2 mr-0">
                                <h3>Residential Premium Pool</h3>
                                <p class="tiny-title my-3">Perfect and trimmed to perfection with amazing speeds. This Pool will work on nearly every Site with Speeds down to 150ms.</p>
                                <div class="d-flex flex-row flex-wrap justify-content-between">
                                    <h6 class="dark-text">Select Amount</h6>
                                    <div class="w-100"></div>
                                    <form v-on:submit.prevent class="w-100">
                                        <div class="d-flex justify-content-between w-100 flex-wrap">
                                            <select v-model="products.premium" class="dropdown no-border dark-text" :disabled="residentialStatus == false">
                                                <option value="DISABLED" :hidden="residentialStatus">Temporarily Disabled</option>
                                                <option value="PREM1" :disabled="premiumStock < 1">1 GB / 12 €<div v-if="premiumStock < 1"> - OOS</div></option>
                                                <option value="PREM2" :disabled="premiumStock < 2">2 GB / 24 €<div v-if="premiumStock < 2"> - OOS</div></option>
                                                <option value="PREM5" :disabled="premiumStock < 5">5 GB / 60 €<div v-if="premiumStock < 5"> - OOS</div></option>
                                                <option value="PREM10" :disabled="premiumStock < 10">10 GB / 120 €<div v-if="premiumStock < 10"> - OOS</div></option>
                                                <option value="PREM20" :disabled="premiumStock < 20">20 GB / 240 €<div v-if="premiumStock < 20"> - OOS</div></option>
                                                <option value="PREM50" :disabled="premiumStock < 50">50 GB / 500 €<div v-if="premiumStock < 50"> - OOS</div></option>
                                                <option value="PREM100" :disabled="premiumStock < 50">100 GB / 1000 €<div v-if="premiumStock < 50"> - OOS</div></option>
                                            </select>
                                            <input :disabled="residentialStatus == false || premiumStock < 1" v-on:click="selectProduct" data-toggle="modal" data-target="#exampleModal" id="premium" type="submit" class="purchase-button no-border bold" value="Purchase">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col"></div>
                    </div>
                </div>
                <div class="w-100">
                    <div class="row d-flex flex-wrap mb-3 mt-4">
                        <h1 class="page-title bold mb-sm-5 mb-4 mt-1">Datacenter Plan Options</h1>
                        <div class="w-100"></div>
                        <div class="col-12 mb-3 col-lg-6 pl-0 mt-3">
                            <div class="jumbotron w-90  rounded-corner product-box mr-sm-2 mr-0">
                                <h3>Infinity Zalando Proxies</h3>
                                <p class="tiny-title my-3">Perfect for Zalando. Insane Speeds. Proxy location as close as possible to Zalando Datacenters. Hitting Speeds around 30 ms.</p>
                                <div class="d-flex flex-row flex-wrap justify-content-between">
                                    <h6 class="dark-text">Select Amount</h6>
                                    <div class="w-100"></div>
                                    <form v-on:submit.prevent class="w-100">
                                        <div class="d-flex justify-content-between w-100 flex-wrap">
                                            <select :disabled="datacenterStatus == false" v-model="products.zalando" class="dropdown no-border dark-text">
                                                <option value="DISABLED" :hidden="datacenterStatus">Temporarily Disabled</option>
                                                <option value="ZAL25" :disabled="zalandoStock < 25">25 / 47.50 €<div v-if="zalandoStock < 25"> - OOS</div></option>
                                                <option value="ZAL50" :disabled="zalandoStock < 50">50 / 95 €<div v-if="zalandoStock < 50"> - OOS</div></option>
                                                <option value="ZAL100" :disabled="zalandoStock < 100">100 / 185 €<div v-if="zalandoStock < 100"> - OOS</div></option>
                                                <option value="ZAL200" :disabled="zalandoStock < 200">200 / 365 €<div v-if="zalandoStock < 200"> - OOS</div></option>
                                                <option value="ZAL250" :disabled="zalandoStock < 250">250 / 465 €<div v-if="zalandoStock < 250"> - OOS</div></option>
                                            </select>
                                            <input :disabled="datacenterStatus == false || zalandoStock < 25" v-on:click="selectProduct" data-toggle="modal" data-target="#exampleModal" id="zalando" type="submit" class="purchase-button no-border bold" value="Purchase">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-3 col-lg-6 pl-0 mt-sm-3 mt-2">
                            <div class="jumbotron w-90  rounded-corner product-box ml-sm-2 ml-0">
                                <h3>Datacenter Proxies</h3>
                                <p class="tiny-title my-3">Perfect for any Sneaker Site you want to run. Including Mesh, Footsites EU, Supreme and many more. Located in Germany/Frankfurt.</p>
                                <div class="d-flex flex-row flex-wrap justify-content-between">
                                    <h6 class="dark-text">Select Amount</h6>
                                    <div class="w-100"></div>
                                    <form v-on:submit.prevent class="w-100">
                                        <div class="d-flex justify-content-between w-100 flex-wrap">
                                            <select :disabled="datacenterStatus == false" v-model="products.dc" class="dropdown no-border dark-text">
                                                <option value="DISABLED" :hidden="datacenterStatus">Temporarily Disabled</option>
                                                <option value="DC25" :disabled="dcStock < 25">25 / 45 € <div v-if="dcStock < 25"> - OOS</div></option>
                                                <option value="DC50" :disabled="dcStock < 50">50 / 90 € <div v-if="dcStock < 50"> - OOS</div></option>
                                                <option value="DC100" :disabled="dcStock < 100">100 / 175 € <div v-if="dcStock < 100"> - OOS</div></option>
                                                <option value="DC200" :disabled="dcStock < 200">200 / 340 € <div v-if="dcStock < 200"> - OOS</div></option>
                                                <option value="DC250" :disabled="dcStock < 250">250 / 430 € <div v-if="dcStock < 250"> - OOS</div></option>
                                            </select>
                                            <input :disabled="datacenterStatus == false || dcStock < 25" v-on:click="selectProduct" data-toggle="modal" data-target="#exampleModal" id="dc" type="submit" class="purchase-button no-border bold" value="Purchase">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-3 col-lg-6 pl-0 mt-sm-3 mt-2">
                            <div class="jumbotron w-90  rounded-corner product-box mr-sm-2 mr-0">
                                <h3>Daily Datacenter Proxies</h3>
                                <p class="tiny-title my-3">Perfect for pretty much any Sneaker site. Including Mesh, Footsites EU, Zalando, Supreme and many more. Located in Europe Countries Mixed. Will last until initial drop is sold out. Not lasting 24hours.</p>
                                <div class="d-flex flex-row flex-wrap justify-content-between">
                                    <h6 class="dark-text">Select Amount</h6>
                                    <div class="w-100"></div>
                                    <form v-on:submit.prevent class="w-100">
                                        <div class="d-flex justify-content-between w-100 flex-wrap">
                                            <select :disabled="datacenterStatus == false" v-model="products.daily_dc" class="dropdown no-border dark-text">
                                                <option value="DISABLED" :hidden="datacenterStatus">Temporarily Disabled</option>
                                                <option value="DAIL25" :disabled="dailyDcStock < 25">25 / 17.50 € <div v-if="dailyDcStock < 25"> - OOS</div></option>
                                                <option value="DAIL50" :disabled="dailyDcStock < 50">50 / 35 € <div v-if="dailyDcStock < 50"> - OOS</div></option>
                                                <option value="DAIL75" :disabled="dailyDcStock < 75">75 / 52.50 € <div v-if="dailyDcStock < 75"> - OOS</div></option>
                                                <option value="DAIL100" :disabled="dailyDcStock < 100">100 / 70 € <div v-if="dailyDcStock < 100"> - OOS</div></option>
                                                <option value="DAIL150" :disabled="dailyDcStock < 150">150 / 105 € <div v-if="dailyDcStock < 150"> - OOS</div></option>
                                                <option value="DAIL200" :disabled="dailyDcStock < 200">200 / 140 € <div v-if="dailyDcStock < 200"> - OOS</div></option>
                                                <option value="DAIL250" :disabled="dailyDcStock < 250">250 / 175 € <div v-if="dailyDcStock < 250"> - OOS</div></option>
                                                <option value="DAIL300" :disabled="dailyDcStock < 300">300 / 210 € <div v-if="dailyDcStock < 300"> - OOS</div></option>
                                                <option value="DAIL400" :disabled="dailyDcStock < 400">400 / 280 € <div v-if="dailyDcStock < 400"> - OOS</div></option>
                                                <option value="DAIL500" :disabled="dailyDcStock < 500">500 / 350 € <div v-if="dailyDcStock < 500"> - OOS</div></option>
                                            </select>
                                            <input :disabled="datacenterStatus == false || dailyDcStock < 25" v-on:click="selectProduct" data-toggle="modal" data-target="#exampleModal" id="daily_dc" type="submit" class="purchase-button no-border bold" value="Purchase">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex flex-wrap mb-4">
                        <h1 class="page-title bold mb-4 mt-1">ISP Plan Options</h1>
                        <div class="w-100"></div>
                        <div class="col-12 mb-3 col-lg-6 pl-0 mt-2">
                            <div class="jumbotron w-90  rounded-corner product-box mr-sm-2 mr-0">
                                <h3>Infinity Private Telecom ISP Proxies</h3>
                                <p class="tiny-title my-3">High quality German Telecom ISPS for any Site you want to run. Including Mesh, Footsites EU, Supreme and many more.</p>
                                <div class="d-flex flex-row flex-wrap justify-content-between">
                                    <h6 class="dark-text">Select Amount</h6>
                                    <div class="w-100"></div>
                                    <form v-on:submit.prevent class="w-100">
                                        <div class="d-flex justify-content-between w-100 flex-wrap">
                                            <select :disabled="datacenterStatus == false" v-model="products.isp" class="dropdown no-border dark-text">
                                                <!--<option value="COMINGSOON" :hidden="datacenterStatus">Coming Soon</option>-->
                                                <option value="DISABLED" :hidden="datacenterStatus">Temporarily Disabled</option>
                                                <option value="ISP25" :disabled="ispStock < 25">25 / 50 € <div v-if="ispStock < 25"> - OOS</div></option>
                                                <option value="ISP50" :disabled="ispStock < 50">50 / 100 € <div v-if="ispStock < 50"> - OOS</div></option>
                                                <option value="ISP100" :disabled="ispStock < 100">100 / 200 € <div v-if="ispStock < 100"> - OOS</div></option>
                                                <option value="ISP200" :disabled="ispStock < 200">200 / 390 € <div v-if="ispStock < 200"> - OOS</div></option>
                                                <option value="ISP250" :disabled="ispStock < 250">250 / 480 € <div v-if="ispStock < 250"> - OOS</div></option>
                                            </select>
                                            <input :disabled="datacenterStatus == false || ispStock < 25" v-on:click="selectProduct" data-toggle="modal" data-target="#exampleModal" id="isp" type="submit" class="purchase-button no-border bold" value="Purchase">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col"></div>
                    </div>
                </div>
                <Footer class="w-100 mt-4"/>
            </div> 
        </div>
        <div v-else class="h-100">
            <Disabled/>
        </div>       
    </div>
    <Loading v-else/>
</div>
</template>

<script>
import Footer from './Footer.vue'
import Heading from './Heading.vue'
import Loading from './Loading.vue'
import Disabled from './Disabled.vue'

import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'

//const stripePromise = loadStripe('pk_test_51IMOAhBRvxL62MvCXUnn9Dg6ngwYEKSFXPIhMZvft4gYOuP0ILrhrAOJrtIpc3J4FZSgZVcDJ8ARQfTzQZ5046Ff00z6bKmy3s')
const stripePromise = loadStripe('pk_live_51IGsg0HfKZTkYhgYHMrRpExw7vsCaMZkZOrZQkmcwoxKf7ANSr5PVI9YUPDTw6u9N5BgqvJiXyXAZcnddk0EW2ST005SpInI3H')

export default {
    name: 'Products',
    components: {
        Footer,
        Heading,
        Loading,
        Disabled
    },
    data() {
        return {
            loaded: false,
            username: null,
            profilePicUrl: null,
            products: {
                isp: 'ISP25',
                dc: 'DC25',
                zalando: 'ZAL25',
                mesh: 'MESH1',
                budget: 'BUGT1',
                premium: 'PREM1',
                daily_dc: 'DAIL25'
            },
            zalandoStock: 0,
            ispStock: 0,
            dcStock: 0,
            meshStock: 0,
            budgetStock: 0,
            premiumStock: 0,
            pageStatus: null,
            residentialMeshStatus: null,
            residentialStatus: null,
            datacenterStatus: null,
            discountCode: null,
            selectedProduct: null
        }
    },
    methods: {
        selectProduct(event) {
            this.selectedProduct = this.products[event.target.id]
        },
        async checkout(event) {
            event.target.disabled = true
            event.target.innerHTML = 'Redirecting to checkout'
            let sessionId = this.$parent.$parent.session.session_id // change later
            let stripe = await stripePromise

            let data = {
                sessionId: sessionId,
                sku: this.selectedProduct
            }

            if (this.discountCode) {
                data.discount = this.discountCode
            }

            await axios.post(
                `${this.$apiUrl}/payments/session`, 
                data
            )
                .catch(async (error) => {
                    if (error.response.data.error.includes('Invalid discount')) {
                        event.target.innerHTML = 'Invalid Coupon'
                        await new Promise(resolve => setTimeout(resolve, 1500))
                        event.target.innerHTML= 'Checkout'
                        event.target.disabled = false
                    } else {
                        console.log(error.response.data)
                        event.target.disabled = false
                        event.target.innerHTML = "Error redirecting"
                    }
                })
                .then(result => {
                    stripe.redirectToCheckout({ sessionId: result.data.id })
                })
        }
    },
    mounted: async function() {
        // check session
        await this.$parent.$parent.checkSession()
        const session = this.$parent.$parent.session
        this.username = `${session.display_name}#${session.tag}`
        this.profilePicUrl = session.profile_picture_url

        if (!session.discord_id) {
            this.$router.push('login')
        }

        await axios.get(`${this.$apiUrl}/pages/status`)
            .then(result => {
                this.pageStatus = result.data.shop
                this.residentialMeshStatus = result.data['mesh']
                if (!this.residentialMeshStatus) {
                    this.products.mesh = 'DISABLED'
                }
                this.residentialStatus = result.data['shop.residential']
                if (!this.residentialStatus) {
                    this.products.budget = 'DISABLED'
                    this.products.premium = 'DISABLED'
                }
                this.datacenterStatus = result.data['shop.datacenter']
                if (!this.datacenterStatus) {
                    this.products.zalando = 'DISABLED'
                    this.products.dc = 'DISABLED'
                    this.products.isp = 'DISABLED'
                }
            })

        if (this.pageStatus == false) {
            this.loaded = true
            return
        }

        // check all stock
        Promise.all([
            axios.get(`${this.$apiUrl}/datacenter/stock/isp`)
                .catch(error => {
                    console.log(error.response.data)
                })
                .then(result => {
                    this.ispStock = result.data.stock
                }),
            axios.get(`${this.$apiUrl}/datacenter/stock/dc`)
                .catch(error => {
                    console.log(error.response.data)
                })
                .then(result => {
                    this.dcStock = result.data.stock
                }),
            axios.get(`${this.$apiUrl}/datacenter/stock/zalando`)
                .catch(error => {
                    console.log(error.response.data)
                })
                .then(result => {
                    this.zalandoStock = result.data.stock
                }),
            axios.get(`${this.$apiUrl}/datacenter/stock/daily_dc`)
                .catch(error => {
                    console.log(error.response.data)
                })
                .then(result => {
                    this.dailyDcStock = result.data.stock
                }),
            axios.get(`${this.$apiUrl}/residential/stock/budget`)
                .catch(error => {
                    console.log(error.response.data)
                })
                .then(result => {
                    this.budgetStock = result.data.stock
                }),
            axios.get(`${this.$apiUrl}/residential/stock/premium`)
                .catch(error => {
                    console.log(error.response.data)
                })
                .then(result => {
                    this.premiumStock = result.data.stock
                }),
            axios.get(`${this.$apiUrl}/residential/stock/mesh`)
                .catch(error => {
                    console.log(error.response.data)
                })
                .then(result => {
                    this.meshStock = result.data.stock
                })
        ])
            .then(() => {
                this.loaded = true
            })
            .catch(error => {
                console.log(error)
            })
    }
}
</script>

<style scoped>
#exampleModal[aria-modal=true] {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.five-margin {
    margin-bottom: 5rem;
}

#discount-box {
    background-color: #202634;
    font-size: 0.9375rem;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
    color: #BDE3F4; 
}

.x-button {
    color: #C2D7E8;
}

.modal-button {
    background-color: #BDE3F4;
    color: #191E29;
}

.modal-button:hover {
    background-color: #aad1e3;
    box-shadow: 5px 0px 10px 5px #00000054 !important;
}

.modal-container {
    background-color: #191E29;
}

.purchase-button:hover {
    background-color: #aad1e3;
    box-shadow: 5px 0px 10px 5px #00000054 !important;
}

h3 {
    font-size: 1.75rem;
}

.dark-text {
    color: #6D7B88
}

.w-90{
    height: 90%;
}

#heading {
    width: calc(100% + 15px) !important;
    margin-left: -15px;
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.dropdown {
    background-color: #202634;
    padding: 10px 20px;
}

.no-border {
    border:0px;
    outline:0px;
}

.nav-item:hover i::before {
    color: #50FFF9;
}

@media (min-width: 992px) and (max-width: 1275px), (max-width: 769px) {
    .purchase-button {
        margin-top: 1rem;
        width: 100%;
    }

    select {
        width: 100%;
    }
}

.active-item {
    color: #BDE3F4 !important;
}

.nav-item:hover > a {
    color: #BDE3F4
}

.nav-item > a {
    color: #191E29;
}

.rounded-corner {
    border-radius: 5px;
}

.product-box {
    background-color: #191E29;
    padding-top: 25px;
    padding-bottom: 15px;
}

.purchase-button {
    background-color: #BDE3F4;
    color: #191E29;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: clamp(5px, 15%, 70px);
    padding-right: clamp(5px, 15%, 70px);
    border-radius: 5px;
}

#footer > a {
    margin-left: 2.5%;
    margin-right: 2.5%;
}

#menu-bar {
    background-color: #0D0A17;
}

#profile-container {
    border-radius: 10px;
    background-color: #191E29;
    padding: 8px 15px;
    margin-bottom: 10px;
}

#profile-pic {
    border-radius: 50%;
}

@media (min-width: 768px) {
    #menu-bar {
        max-width: 350px;
    }
}

@media (max-width: 767px) {
    #header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    #profile-container {
        display: none;
    }
}

@media (max-width: 576px) {
    .jumbotron {
        margin-bottom: 0;
    }
    .w-90 {
        height: 100%;
    }
}
</style>
