<template>
<div class="row min-vh-100">
  <DashNav :current-nav="'shop'"/>
  <Products class="mx-4 ml-sm-4 ml-0 pl-4 mt-4 pt-sm-3 pt-0 mb-4 pr-md-4 pr-2" id="products-container"/>
</div>
</template>

<script>
import DashNav from '../components/dashboard/DashNav.vue'
import Products from '../components/dashboard/Products.vue'

export default {
  name: 'Shop',
  components: {
    DashNav,
    Products
  }
}
</script>

<style>
#app {
    background-color: #0F1218;
    font-family: 'Montserrat', sans-serif;
    color: #C2D7E8;
    overflow-x: hidden;
    scrollbar-width: none;
}

.min-vh-100 {
    min-height: 100vh;
}

body::-webkit-scrollbar {
    display: none;
}
</style>
