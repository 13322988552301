<template>
    <div class="align-items-center justify-content-center d-flex vh-100 flex-column">
        <div v-if="loaded" id="login-menu-container">
            <div>
                <div class="row d-flex justify-content-center">
                    <Logo id="logo"/>
                </div>
                <div class="row d-flex flex-column justify-content-center mt-3">
                    <h1 class="text-center login-title w-100 mb-0">INFINITY</h1>
                    <h1 class="text-center login-title w-100">Supply</h1>
                </div>
                <div class="row d-flex justify-content-center mt-5 pt-4">
                    <button class="purchase-button no-border w-100" id="login-button">
                        <a :href="oauthUrl">Login with Discord</a>
                    </button>
                </div>
            </div>
            <div>
            </div>
        </div>
        <Loading v-else/>
        <div class="w-100 mb-3">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0 flex-row justify-content-start w-100 align-items-center" id="menu-items">
                <li class="nav-item">
                    <router-link class="nav-link semi-bold" to="/">
                        <i class="bi bi-box-arrow-in-left" style="font-size: 1rem; color: #191E29;"></i>                           
                        Back to Home
                    </router-link>
                    <router-view/>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Logo from './Logo.vue'
import Cookies from 'js-cookie'
import Loading from './dashboard/Loading'

export default {
    name: 'Login',
    components: {
        Logo,
        Loading
    },
    mounted: async function() {
        await this.$parent.$parent.checkSession()
        this.oauthUrl = `https://discord.com/oauth2/authorize?client_id=818808143529508864&redirect_uri=${`${encodeURIComponent(`${this.$apiUrl}/oauth`)}`}&response_type=code&scope=identify&state=${Cookies.get('sessionId')}`
        this.loaded = true
    },
    data() {
        return {
            oauthUrl: '',
            loaded: false
        }
    }
}
</script>

<style>
#login-menu-container {
    margin-top: auto;
    margin-bottom: auto;
}

.nav-item > a {
    color: #C2D7E8;
}

#logo {
    width: 300px !important;
    height: auto !important;
}

.login-title {
    font-family: 'Kilton';
    letter-spacing: 0.3em;
    text-indent: 0.6em;
}

.purchase-button {
    background-color: #BDE3F4;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    font-weight: 600;
}

#login-button {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 65px;
    padding-right: 65px;
    font-size: 0.9375rem;
}

#login-button > a {
    color: inherit;
    text-decoration: none;
    font-size: 0.9375;
}
</style>