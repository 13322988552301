<template>
    <div class="loading-screen d-flex justify-content-center align-items-center h-100">
        This page is currently disabled for maintenance.
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>