<template>
<div class="col">
    <div :hidden="loaded == false" class="h-100">
        <div v-if="pageStatus" class="h-100">
            <div class="d-flex justify-content-between flex-wrap h-100">
                <div class="justify-content-between row d-flex flex-wrap pt-lg-2">
                    <Heading :pageTitle="'Residential Proxy Dashboard'" :username="username" :profilePicLink="profilePicUrl" class="px-3 pb-3 mt-sm-1 mt-0 mb-3 mb-sm-1"/>
                    <div class="col-lg-3 col-xl-2 pb-0 mb-sm-4 mb-0">
                        <div class="jumbotron rounded-corner product-box pb-0 h-100 px-3 d-flex flex-row justify-content-between flex-column">
                            <div>
                                <h3 class="tiny-title">Data Usage</h3>
                                <div class="mt-4 progress-bar-container" id="dc-count">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-xl-7 mb-4 px-lg-3 d-sm-block">
                        <div class="jumbotron rounded-corner product-box h-100 pb-0 d-flex flex-column justify-content-center align-items-center">
                            <h3 class="tiny-title">Data Graph</h3>
                            <canvas id="data-graph"></canvas>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-3 pb-0 mb-4 px-lg-3">
                        <div class="jumbotron rounded-corner product-box h-100 pb-0 progress-box d-sm-block d-none">
                            <h3 class="tiny-title">Recent Orders</h3>
                            <div class="box-background rounded-corner w-100 mt-4">
                                <div class="row pb-2 mx-0" id="top-row">
                                    <div class="col-6 semi-bold px-0 cell">
                                        Plan
                                    </div>
                                    <div class="col-3 semi-bold px-0 cell">
                                        Data
                                    </div>
                                    <div class="col-3 semi-bold px-0 cell d-flex justify-content-end pr-lg-4">
                                        Price
                                    </div>
                                </div>
                                <div v-for="purchase in purchases" :key="purchase.timestamp" class="row mt-3 mb-3 pb-1 mx-0">
                                    <div class="col-6 px-0 cell">
                                        {{ purchase.name }}
                                    </div>
                                    <div class="col-3 px-0 cell">
                                        {{ purchase.quantity }} GB
                                    </div>
                                    <div class="col-3 px-0 cell d-flex justify-content-end pr-lg-4">
                                        €{{ purchase.price }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-xl-9 pb-0 mb-4 mb-sm-0">
                        <div class="jumbotron rounded-corner product-box h-100 pt-3 pb-0">
                            <form v-on:submit.prevent class="w-100 h-100 mb-5 pb-1 px-lg-3">
                                <div class="d-flex row h-100">
                                    <div class="col-lg-4 col-xl-3 pt-4">
                                        <div class="px-xl-1">
                                            <p class="select-label dark-text mb-1">Residential Plan</p>
                                            <select v-model="pool" @change="progressBar(); graph(); proxyPoolChange();" class="dropdown no-border dark-text px-3 mb-2 w-100">
                                                <option value="mesh">Mesh</option>
                                                <option value="budget">Budget</option>
                                                <option value="premium">Premium</option>
                                            </select>                                
                                            <p class="select-label dark-text mb-1">Type</p>
                                            <select v-model="proxyType" class="dropdown no-border dark-text px-3 mb-2 w-100">
                                                <option value="sticky"><div v-if="pool == 'mesh'">Disabled</div><div v-else>Sticky</div></option>
                                                <option value="rotating" :disabled="pool == 'mesh'">Rotating</option>
                                            </select> 
                                            <p class="select-label dark-text mb-1">Location</p>
                                            <select v-if="pool == 'budget'" v-model="location" class="dropdown no-border dark-text px-3 mb-2 w-100">
                                                    <option v-for="region in budgetRegions" :key="region" :value="region.replaceAll(' ', '')">{{ region }}</option>
                                            </select>   
                                            <select v-if="pool == 'premium'" v-model="location" class="dropdown no-border dark-text px-3 mb-2 w-100">
                                                    <option v-for="region in premiumRegions" :key="region" :value="region">{{ region }}</option>
                                            </select>
                                            <select v-if="pool == 'mesh'" v-model="location" class="dropdown no-border dark-text px-3 mb-2 w-100">
                                                    <option v-for="region in meshRegions" :key="region" :value="region">{{ region }}</option>
                                            </select>
                                            <p class="select-label dark-text mb-1">Amount</p>
                                            <input v-model="proxyCount" type="number" class="dropdown no-border dark-text px-3 mb-2 w-100" style="border-radius: 5px;">
                                        </div>
                                    </div>
                                    <div class="col-12 w-100 d-sm-none d-inline-block">
                                        <button v-on:click="generateProxies" class="purchase-button mr-5 mt-4 no-border">
                                            Generate Proxies
                                        </button>
                                    </div>
                                    <div class="col-lg-8 col-xl-9 w-100 mt-3 text-area-container">
                                        <div class="px-xl-1 h-100">
                                            <div class="jumbotron mb-0 pt-2 text-area rounded-corner pb-0 h-100 d-flex align-items-start flex-column">
                                                <p v-for="proxy in generatedProxies" :key="proxy" class="mb-0 proxy-container">
                                                    {{ proxy }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 w-100">
                                        <div class="px-md-1">
                                            <button v-on:click="generateProxies" class="purchase-button mr-xl-5 mr-3 mt-4 no-border d-sm-inline-block d-none">
                                                Generate Proxies
                                            </button>
                                            <button v-on:click="copyProxies" class="purchase-button mr-xl-5 mr-3 mt-xl-4 mt-3 no-border">
                                                Copy All
                                            </button>
                                            <button v-on:click="clearProxies" class="purchase-button mt-3 no-border">
                                                Clear All
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-3 mt-sm-4 mt-lg-0 pl-lg-3">
                        <div class="jumbotron rounded-corner product-box h-100 mb-0 pb-4">
                            <h3 class="tiny-title mb-4">Customer Overview</h3>
                            <h4 class="tiny-title subtitle-muted mt-3">Total Residential Data:</h4>
                            <div class="fixed-medium-text medium mb-3 d-flex flex-row"> <div id="total-resi" class="mr-1"></div> GB</div>
                            <h4 class="tiny-title subtitle-muted pt-3">Available plans:</h4>
                            <!--<h3 class="tiny-title mb-3">Residential Mesh - 1.49 GB</h3>-->
                            <div class="tiny-title mb-3 d-flex flex-row"> <div class="mr-1">Residential Mesh: </div><div id="mesh-resi" class="mr-1"></div> GB</div>
                            <div class="tiny-title mb-3 d-flex flex-row"> <div class="mr-1">Residential Premium: </div><div id="premium-resi" class="mr-1"></div> GB</div>
                            <div class="tiny-title mb-3 d-flex flex-row"> <div class="mr-1">Residential Budget: </div><div id="budget-resi" class="mr-1"></div> GB</div>
                        </div>
                    </div>
                </div>
                <Footer class="mr-1 mb-0 w-100 mt-5 pt-4 pl-4 pr-2" id="footer"/>
            </div>
        </div>
        <div v-else class="h-100">
            <Disabled/>
        </div>
    </div>
    <Loading v-if="loaded == false"/>
</div>
</template>

<script>
import ProgressBar from 'progressbar.js'
import Footer from './Footer.vue'
import Chart from 'chart.js'
import Heading from './Heading.vue'
import Loading from './Loading.vue'
import Disabled from './Disabled.vue'
import { CountUp } from 'countup.js';

import axios from 'axios'

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  name: 'UserDashboard',
  components: {
      Footer,
      Heading,
      Loading,
      Disabled
  },
  data() {
      return {
            username: null,
            profilePicUrl: null,
            pool: 'budget',
            location: 'Random',
            loaded: false,
            meshRegions: [],
            budgetRegions: [],
            premiumRegions: [],
            proxyCount: 0,
            proxyType: 'sticky',
            generatedProxies: [],
            purchases: [],
            meshResi: 0,
            unusedMeshResi: 0,
            budgetResi: 0,
            unusedBudgetResi: 0,
            premiumResi: 0,
            unusedPremiumResi: 0,
            budgetDataUsageHistory: [],
            premiumDataUsageHistory: [],
            pageStatus: null,
            purchase: this.$route.query.purchase,
      }
  },
  methods: {
        proxyPoolChange() {
            if (this.pool == 'premium') {
                this.location = 'Germany'
            } 
            else if (this.pool == 'budget') {
                this.location = 'Random'
            } 
            else if (this.pool == 'mesh') {
                this.location = 'Pool 1'
            }
        },
        analytics() {
          const totalResiCount = new CountUp('total-resi', this.unusedMeshResi +this.unusedBudgetResi + this.unusedPremiumResi, { duration: 0.5, decimalPlaces: 2 })
          const meshCount = new CountUp('mesh-resi', this.unusedMeshResi, { duration: 0.5, decimalPlaces: 2 })
          const budgetCount = new CountUp('budget-resi', this.unusedBudgetResi, { duration: 0.5, decimalPlaces: 2 })
          const premiumCount = new CountUp('premium-resi', this.unusedPremiumResi, { duration: 0.5, decimalPlaces: 2 })
          totalResiCount.start()
          meshCount.start()
          budgetCount.start()
          premiumCount.start()
      },
        async generateProxies(event) {
            event.target.disabled = true
            event.target.innerText = "Generating..."
        
            await axios.post(`${this.$apiUrl}/residential/generate`, {
                sessionId: this.$parent.$parent.session.session_id,
                poolName: this.pool,
                proxyCount: this.proxyCount,
                country: this.location,
                type: this.proxyType
            })
                .then(result => {
                    this.generatedProxies = result.data
                })
                .catch(error => {
                    if (error.response.status == 400) {
                        this.generatedProxies = ['Purchase data before generating proxies']
                    }
                })
            event.target.disabled = false
            event.target.innerText = "Generate Proxies"
        },
        clearProxies() {
            this.generatedProxies = []
        },
        async copyProxies(event) {
            await navigator.clipboard.writeText(this.generatedProxies.join('\n'))
            event.target.innerText = "Copied"
            await sleep(1500)
            event.target.innerText = "Copy All"
        },
        progressBar() {
            let totalData
            let unusedData
            let usedData

            switch (this.pool) {
                case 'mesh':
                    totalData = this.meshResi.toFixed(2)
                    unusedData = this.unusedMeshResi.toFixed(2)
                    usedData = (this.meshResi - this.unusedMeshResi).toFixed(2)
                    break;
                case 'budget':
                    totalData = this.budgetResi.toFixed(2)
                    unusedData = this.unusedBudgetResi.toFixed(2)
                    usedData = (this.budgetResi - this.unusedBudgetResi).toFixed(2)
                    break;
                case 'premium':
                    totalData = this.premiumResi.toFixed(2)
                    unusedData = this.unusedPremiumResi.toFixed(2)
                    usedData = (this.premiumResi - this.unusedPremiumResi).toFixed(2)
                    break;
            }
            document.getElementById('dc-count').innerHTML = ''
            var now = new Date().getTime()
            var progressBar = new ProgressBar.Circle('#dc-count', {
                color: '#BDE3F4',
                trailWidth: 10,
                trailColor: '#202634',
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    autoStyleContainer: false
                },
                from: { color: '#BDE3F4', width: 10 },
                to: { color: '#BDE3F4', width: 10 },
                // Set default step function for all animate calls
                step: function(state, circle) {
                    circle.path.setAttribute('stroke', state.color);
                    circle.path.setAttribute('stroke-width', state.width);
                    let animationPercent = ((new Date().getTime()) - now) / 1400
                    if (animationPercent > 1) {
                        animationPercent = 1
                    } else if (animationPercent >= 0.90) {
                        animationPercent = 1
                    }
                    circle.setText(`<p class="bold canva-text mt-2 pt-1" style="margin: 0;">${parseFloat(unusedData * animationPercent).toFixed(2)} GB</p><p class="progress-bar-text-secondary" style="margin: 0; font-size: 0.688rem !important;">${parseFloat(usedData * animationPercent).toFixed(2)} GB used</p>`)
                }
            });
            
            progressBar.text.style.textAlign = 'center'
            progressBar.text.className += ' progress-bar-text-static'
            progressBar.text.style.whiteSpace = 'nowrap'
            progressBar.animate(1 - (usedData / totalData));  
        },
        graph() {
            var canvas = document.getElementById('data-graph')

            var gradient = canvas.getContext("2d").createLinearGradient(0, 0, 0, 200)
            gradient.addColorStop(0, '#BDE3F4')
            gradient.addColorStop(0, '#27314d')

            let labels = []
            let today = new Date()
            today = today.setUTCHours(0,0,0,0)

            for (let i = 0; i < 5; i++) {
                labels.push(today - (1000 * 60 * 60 * 24 * i))
            }

            let data = [0, 0, 0, 0, 0]

            switch (this.pool) {
                case 'mesh':
                    for (let i = 0; i < this.meshDataUsageHistory.length; i++) {
                        const arrayPos = labels.indexOf(parseInt(this.meshDataUsageHistory[i].date))
                        if (arrayPos != -1) {
                            data[arrayPos] += this.meshDataUsageHistory[i].data * 1000
                        }
                    }   
                    break;

                case 'budget':
                    for (let i = 0; i < this.budgetDataUsageHistory.length; i++) {
                        const arrayPos = labels.indexOf(parseInt(this.budgetDataUsageHistory[i].date))
                        if (arrayPos != -1) {
                            data[arrayPos] += this.budgetDataUsageHistory[i].data * 1000
                        }
                    }   
                    break;
            
                case 'premium':
                    for (let i = 0; i < this.premiumDataUsageHistory.length; i++) {
                        const arrayPos = labels.indexOf(parseInt(this.premiumDataUsageHistory[i].date))
                        if (arrayPos != -1) {
                            data[arrayPos] += this.premiumDataUsageHistory[i].data * 1000
                        }
                    }
                    break;
            }

            for (let i = 0; i < labels.length; i++) {
                const date = new Date(labels[i])
                labels[i] = `${date.toLocaleString('default', { month: 'long' }).slice(0, 3)} ${date.getDate()}`
            }

            switch (this.pool) {
                case 'mesh':
                    data[0] = this.unusedMeshResi * 1000
                    break;
                case 'budget':
                    data[0] = this.unusedBudgetResi * 1000
                    break;
                case 'premium':
                    data[0] = this.unusedPremiumResi * 1000
                    break;
            }
            
            new Chart(canvas, {
                type: 'line',
                data: {
                    labels: labels.reverse(),
                    datasets: [{
                        label: '',
                        data: data.reverse(),
                        backgroundColor: gradient,
                        borderWidth: 1
                    }]
                },
                options: {
                    responsive: true,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display:false
                            },
                            ticks: {
                                fontSize: 12,  
                                fontColor: '#849FB4',  
                                fontFamily: 'Montserrat'
                            }                
                        }],
                        yAxes: [{
                            gridLines: {
                                display:false
                            },
                            ticks: {
                                fontSize: 12,
                                stepSize: 1000 ,
                                fontColor: '#849FB4',
                                fontFamily: 'Montserrat',
                                textAlign: 'left',
                                callback: function(label) {
                                    if (label == 0) {
                                        return `0`
                                    } else {
                                        return `${label / 1000} GB`
                                    }
                                },
                                suggestedMin: 0,
                                suggestedMax: 4000
                            }
                        }]
                    },
                    elements: {
                        point:{
                            radius: 3
                        }
                    },
                    legend: {
                        display: false
                    }
                }
            })
        }
  },
  mounted: async function() {
    // check session
    await this.$parent.$parent.checkSession()
    const session = this.$parent.$parent.session
    this.username = `${session.display_name}#${session.tag}`
    this.displayName = session.display_name
    this.profilePicUrl = session.profile_picture_url

    if (!session.discord_id) {
        this.$router.push('login')
    }

    await axios.get(`${this.$apiUrl}/pages/status`)
        .then(result => {
            this.pageStatus = result.data.residential
        })

    if (this.pageStatus == false) {
        this.loaded = true
        return
    }

    await axios.get(`${this.$apiUrl}/users/${session.discord_id}/purchases`, {
        params: {
            sessionId: session.session_id
        }
    })
        .then(result => {
            const data = result.data.reverse()
            let purchasesTracked = 0;
            for (let i = 0; i < data.length && purchasesTracked < 5; i++) {
                let purchase = {}
                purchase.quantity = parseInt(data[i].sku.replace(/[A-Z]+/, ''))
                purchase.timestamp = data[i].timestamp
                purchase.price = data[i].price / 100

                switch (data[i].sku.replace(/\d+/, '')) {
                    case 'MESH':
                        purchase.name = 'Residential Mesh'
                        this.purchases.push(purchase)
                        purchasesTracked++
                        break; 
                    case 'BUGT':
                        purchase.name = 'Residential Budget'
                        this.purchases.push(purchase)
                        purchasesTracked++
                        break;   
                    case 'PREM':
                        purchase.name = 'Residential Premium'
                        this.purchases.push(purchase)
                        purchasesTracked++  
                        break                   
                }
            }
        })
        .catch(error => {
            if (!error.message.includes('404')) {
                console.log(error.response.body)
            }
            this.$router.push('login')
        })

    Promise.all([
        axios.get(`${this.$apiUrl}/residential/regions/budget`)
            .then(result => {
                for (let i = 0; i < result.data.length; i++) {
                    this.budgetRegions.push(result.data[i].replace( /([A-Z])/g, " $1" ).slice(1).replace('of', ' of'))
                }
            }),
        axios.get(`${this.$apiUrl}/residential/${session.discord_id}`, {
            params: {
                sessionId: session.session_id
            }
        })
            .then(result => {
                if (result.data.budget) {
                    this.budgetResi = parseFloat(result.data.budget.totalData)
                    this.unusedBudgetResi = parseFloat(result.data.budget.remainingData)
                    this.budgetDataUsageHistory = result.data.budget.usage
                } 
                if (result.data.premium) {
                    this.premiumResi = parseFloat(result.data.premium.totalData)
                    this.unusedPremiumResi = parseFloat(result.data.premium.remainingData || 0)
                    this.premiumDataUsageHistory = result.data.premium.usage
                    
                    if (result.data.premium.remainingData == null && this.$route.query.purchase == 'prem') {
                        this.purchase = 'prem-new'
                    }
                }
                if (result.data.mesh) {
                    this.meshResi = parseFloat(result.data.mesh.totalData)
                    this.unusedMeshResi = parseFloat(result.data.mesh.remainingData || 0)
                    this.meshDataUsageHistory = result.data.mesh.usage
                    
                    if (result.data.mesh.remainingData == null && this.$route.query.purchase == 'mesh') {
                        this.purchase = 'mesh-new'
                    }
                }
            }),
        axios.get(`${this.$apiUrl}/residential/regions/mesh`)
            .then(result => {
                for (let i = 0; i < result.data.length; i++) {
                    this.meshRegions.push(result.data[i])
                }
            }),
        axios.get(`${this.$apiUrl}/residential/regions/premium`)
            .then(result => {
                for (let i = 0; i < result.data.length; i++) {
                    this.premiumRegions.push(result.data[i])
                }
            })
    ])
        .then(() => {
            this.loaded = true
            this.analytics()
            this.progressBar()
            this.graph()
            switch (this.purchase) {
                case 'mesh-new':
                    this.$toast.success("Successfully purchased mesh plan data. Since you're a first time buyer, the data will take 3-5 minutes to show up")   
                    break;
                case 'mesh':
                    this.$toast.success("Successfully purchased mesh plan data")
                    break
                case 'prem-new':
                    this.$toast.success("Successfully purchased premium plan data. Since you're a first time buyer, the data will take 3-5 minutes to show up")   
                    break
                case 'prem':
                    this.$toast.success("Successfully purchased premium plan data")
                    break
                case 'bugt':
                    this.$toast.success("Successfully purchased budget plan data")
                    break
            }      
        })
  }
}
</script>

<style scoped>
.proxy-container {
    word-break: break-all;
}

.purchase-button:hover {
    background-color: #aad1e3;
    box-shadow: 5px 0px 10px 5px #00000054 !important;
}

::-webkit-scrollbar {
  width: 10px; 
  background-color: rgba(0,0,0,0);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical {
    background: rgb(71, 71, 71);
    -webkit-border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    min-height: 30px;

}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgb(48, 48, 48); 
  -webkit-border-radius: 100px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

h2 {
    font-size: 1.75rem;
}

#data-graph {
    max-width: 100%;
    max-height: 275px;
}

.progress-bar-container {
    position: relative;
}

.text-area {
    background: #11151E;
    overflow: auto;
    min-height: 250px;
    max-height: 350px;
}

.text-area-container {
    flex: 1 1 auto;
}

#webhook {
    background-color: #202634;
    font-size: 0.9375rem;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
}

#webhook:focus {
    color: #6D7B88;
}

#webhook::placeholder {
  color: #6D7B88;
  opacity: 1;
}

.mb-2-1 {
    margin-bottom: 2.1rem;
}

.dark-text {
    color: #6D7B88
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.dropdown {
    background-color: #202634;
    padding: 10px;
}

.no-border {
    border:0px;
    outline:0px;
}

#account-heading {
    padding-left: 15px;
}

.col-2-5 {
    flex: 0 0 20.83%;
    max-width: 20.83%;
}

@media (max-width: 991px) {
    .col-2-5 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    #profile-pic {
        max-height: 200px;
        max-width: 200px;
    }

    .progress-box {
        font-size: 0.5rem;
    }
}

.nav-item:hover i::before {
    color: #50FFF9;
}

.active-item {
    color: #BDE3F4 !important;
}

.nav-item:hover > a {
    color: #BDE3F4
}

.nav-item > a {
    color: #191E29;
}

.rounded-corner {
    border-radius: 5px;
}

.product-box {
    background-color: #191E29;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.purchase-button {
    background-color: #BDE3F4;
    color: #191E29;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: clamp(5px, 7%, 40px);
    padding-right: clamp(5px, 7%, 40px);
    font-size: 0.9375rem;
    font-weight: 600;
    border-radius: 5px;
    min-width: 220px;
}

#footer > a {
    margin-left: 2.5%;
    margin-right: 2.5%;
}

#menu-bar {
    background-color: #0D0A17;
}

.cell {
    font-size: 0.75rem;
    color: #C2D7E8CC;
    word-wrap: break-word;
}

@media (min-width: 768px) {
    #menu-bar {
        max-width: 350px;
    }
}

@media (max-width: 767px) {
    #header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    #footer {
        margin-top: 8rem;
    }

    #profile-container {
        display: none;
    }

    #total-proxies {
        width: 350px;
        font-size: 80px;
    }
}
@media (max-width: 1200px) {
    .purchase-button {
        width: 100%;
    }
}
@media (max-width: 1200px) {
    .purchase-button {
        min-width: 190px;
    }
}
@media (max-width: 1400px) {
    .purchase-button {
        min-width: 212px;
    }
}
</style>
