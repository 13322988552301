<template>
    <div class="d-flex w-100 justify-content-between align-items-sm-center align-content-start mt-4" id="slogan"> 
        <div id="title-container">
            <div>
                <h1 id="title" class="kilton">INFINITY SUPPLY</h1>
            </div>
            <p id="description" class="mt-sm-2 mt-5 pt-sm-0 pt-2 mb-4">The best Premium Residential and datacenter proxies in the universe, trimmed to perfection to fit the needs of any Reseller around the globe</p>
            <button class="mt-sm-3 mt-1 mb-2 no-border purchase-button" id="shop-button"> 
                <router-link class="nav-link dark-text p-0" to="/shop">
                        <p id="shop-button-text">Visit Shop</p>                 
                </router-link>
                <router-view/>
            </button>
            <p class="sub-text small-text mt-sm-4 mt-2 w-50 muted-text">By purchasing you're accepting the terms of service and refund policy located at the bottom of the website</p>
        </div>
        <Globe id="globe"/>
    </div>
</template>

<script>
import Globe from './Globe.vue'

export default {
    name: 'Slogan',
    components: {
        Globe
    }
}
</script>

<style scoped>
#title {
    font-size: 3.435rem;
    white-space: nowrap;
    letter-spacing: 0.2em;
}

#title-container {
    width: 60%;
}

#description {
    font-size: 1.375rem;
    font-weight: 400;
}

h1 {
    display: inline;
}

#shop-button {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 65px;
    padding-right: 65px;
    font-size: 0.9375rem;
    border-radius: 5px;
}

#shop-button-text {
    white-space: nowrap;
    margin-bottom: 0;
    font-weight: 600;
}

#globe {
    height: clamp(75px, 25%, 350px);
    width: clamp(75px, 25%, 350px);
}

.dark-text {
    color: #1A151D
}

.purchase-button {
    background-color: #BDE3F4;
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width: 572px) {
    #title {
        white-space: normal;
    }
}

@media (max-width: 620px) {
    #title {
        font-size: 2.825rem;
    }

    #description {
        font-size: 0.875rem;
    }

    #title-container > p {
        width: 165% !important;
    }
}

@media (max-width: 350px) {
    #title {
        font-size: 2.225rem;
    }
}
</style>